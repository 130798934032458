<template>
    <talkCurator v-if="openCurator" @closeModal="openCurator = false"/>
    <div class="favorite">
        <div class="favorite__left">
            <platformTable />
        </div>
        <div class="favorite__right">
            <div class="favorite__right__top">
                <div class="favorite__right__top__left">
                    <div class="favorite__right__top__left__title">Корзина</div>
                </div>
                <div class="favorite__right__top__right">
                    <userInfo />
                </div>      
            </div>
            <div class="basketTable">
                <div class="basketTable__top">
                    <p class="basketTable__top__name">Наименование товара</p>
                    <p class="basketTable__top__count">Количество</p>
                    <p class="basketTable__top__price">Цена</p>
                    <div class="basketTable__top__image">
                    </div>
                </div>
                <div class="basketTable__block">
                    <div class="basketTable__block__in" v-for="item in basketItems" :key="item">
                        <div class="basketTable__block__in__left">
                            <div class="basketTable__block__in__left__image">
                                <img :src="item?.premium_option?.icon" v-if="item?.premium_option?.icon"/>
                                <img :src="item?.course?.image" v-if="item?.course?.image"/>
                            </div>
                            <div class="basketTable__block__in__left__text">
                                <p class="basketTable__block__in__left__text__title">{{ item.typeText }}</p>
                                <p class="basketTable__block__in__left__text__sub">{{ item.statusText }}</p>
                            </div>
                        </div>
                        <div class="basketTable__block__in__center"></div>
                        <div class="basketTable__block__in__right">
                            <p v-if="item?.premium_option?.price">{{ item?.premium_option?.price }} KZT</p>
                            <p v-if="item?.course?.price">{{ item?.course?.price }} KZT</p>
                        </div>
                        <div class="basketTable__block__in__image" @click="deleteItem(item.id)">
                            <img src="@/assets/icons/delete.svg"/>
                        </div>
                    </div>  
                </div>
                <div class="basketTable__offer">
                    <input type="checkbox" />
                    <p>Я согласен(-а) с <span>публичной офертой</span></p>
                </div>
                <div class="basketTable__last">
                    <div class="basketTable__last__left">
                        <p>Итого: <span>{{ totalCoast }} KZT</span></p>
                    </div>
                    <button>Купить</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userInfo from '@/components/userInfo.vue';
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import talkCurator from '@/components/talkCurator.vue';
import customSelect from '@/components/customSelect.vue';
import grantCard from '@/components/grantCard.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        userInfo,  platformTable, newCardComponent, talkCurator, customSelect, grantCard
    },
    data(){
        return{
            activeTab: 'list',
            openCurator: false,
            basketItems: [],
            totalCoast: ''
        }
    },
    methods: {
        calculateTotalCost() {
            let total = 0;

            this.basketItems.forEach(item => {
                if (item.course && item.course.price) {
                    total += item.course.price;
                } else if (item.premium_option && item.premium_option.price) {
                    total += item.premium_option.price;
                }
            });

            return total;
        },
        deleteItem(id){
            console.log(id);
            axios.delete(`/platform/client/baskets/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 200) {
                toast.success('Успешно удалено')
                this.getPage()
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        async getPage(){
            await axios.get(`/platform/client/baskets`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.basketItems = res.data.data
                this.totalCoast = this.calculateTotalCost()
            })
            .catch(err => {
            }) 
        },
    },
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.button-pulse {
  animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0 0 white;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 255, 255, 0); 
  }
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.activeTab{
    background-color: white !important;
}
    .favorite{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left{
                display: flex;
                gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                align-items: center;
                &__title{
                    font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                    font-weight: 600;
                }
                &__talk{
                    display: flex;
                    gap: 10px;
                    transition: .5s all ease-in-out;
                    position: relative;
                    align-items: center;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                    background-color: inherit;
                    border: 1px solid #11415D;
                    border-radius: 5px;
                    color: #11415D;
                    font-weight: 700;
                    cursor: pointer;
                    img{
                        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    }
                }
            }
            &__right {
                width: 25%;
            }
        }
        .basketTable{
            display: flex;
            flex-direction: column;
            gap: 40px;
            p, button{
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            }
            &__top{
                display: flex;
                justify-content: space-between;
                &__name, &__count, &__price{
                    width: 32%;
                    text-align: center;
                }
                &__name{
                    text-align: start;
                }
                div{
                    width: 2%;
                }
            }
            &__block{
                display: flex;
                flex-direction: column;
                gap: 25px;
                &__in{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &__left, &__center, &__right{
                        width: 32%;
                    }
                    &__left{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        &__image{
                            width: 40%;
                            img{
                                width: 100%;
                                aspect-ratio: 1;
                                object-fit: cover;
                            }
                        }
                        &__text{
                            display: flex;
                            gap: 10px;
                            flex-direction: column;
                            &__title{
                                color: #FD2D2A;
                            }
                        }
                    }
                    &__right{
                        text-align: center;
                        font-weight: 700;
                    }
                    &__image{
                        width: 2%;
                        cursor: pointer;
                    }
                }
            }
            &__offer{
                display: flex;
                gap: 10px;
                align-items: center;
                input{
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }
                span{
                    color: #FD2D2A;
                    font-weight: 700;
                }
            }
            &__last{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    font-weight: 700;
                }
                button{ 
                    width: 15%;
                    padding: 15px 0;
                    border: none;
                    background: #FD2D2A;
                    color: white;
                    border-radius: 10px;
                }
            }
        }
    }
    
    @media(max-width:768px){
      padding-top: 0px;
      &__left{
          display: none;
      }
      &__right{
          width: 100%;
          padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
          border-radius: 0px; 
          gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
          &__top{
            &__left{
              width: 100%;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              &__title{
                font-size: 21px;
              }
            }
            &__right{
                display: none;
            }
          }
      }
  }
    }
    .search{
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__left{
            width: 63%;
            position: relative;
            input{
                width: 100%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                border: 1px solid #0000001A;
                background-color: inherit;
                border-radius: 10px;
            }
            img{
                width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                position: absolute;
                right: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }
        &__right{
            max-width: 30%;
            display: flex;
            cursor: pointer;
            gap: 5px;
            border-radius: 10px;
            align-items: center;
            gap: 10px;
            border: 1px solid #0000001A;
            padding: 0px 10px;
            p{
                color: #0000008A;
            }
            select{
                border: none;
                background-color: transparent;
            }
        }
        &__images{
            display: none;
            align-items: center;
            justify-content: center;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            width: 23%;
        }
    }
    &__results{
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
        &__in{
            width: 48%;
        }
    }
    @media(max-width:768px){
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        &__top{
            &__left{
                width: 75%;
                input{
                    padding: 10px min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
                img{
                    right: 10px;
                }
            }
            &__right{
                display: none;
            }
            &__images{
                display: flex;
            }
        }
    }
    }
</style>