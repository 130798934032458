<template>
    <div class="modal1Back">
        <div class="modal1">
            <img src="@/assets/icons/exit.svg" class="exit" @click="closeModal()"/>
            <div class="modal1__top">
                <p class="modal1__top__title">Добавить программу</p>
                <p class="modal1__top__sub">Заполните все поля для добавления новой программы</p>
                <div class="line"></div>
            </div>
            <p class="customTitle">Название программы и категоризация</p>
            <div class="modal1__inputs">
                <div class="customInput">
                    <label>Название программы</label>
                    <input type="text" placeholder="University of London" v-model="program_name">
                </div>
                <div class="customInput">
                    <label>Стипендия</label>
                    <customSelect :list="stips" v-model="formData.scholarship_id"/>
                </div>
                <div class="customInput">
                    <label>Степень</label>
                    <customSelect :list="steps" v-model="formData.study_degree_id"/>
                </div>
                <div class="customInput">
                    <label>Категория</label>
                    <customSelect :list="categories" />
                </div>
                <div class="customInput">
                    <label>Sub-category</label>
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>Ссылка на сайт программы</label>
                    <input type="text" placeholder="Ссылка">
                </div>
                <div class="customInput">
                    <label>Продолжительность обучения</label>
                    <input type="number"/>
                </div>
                <div class="customInput">
                    <label>GPA</label>
                    <input type="text" v-model="formData.gpa"/>
                </div>
                <div class="customInput">
                    <label>IELTS/TOEFL</label>
                    <input type="text" v-model="formData.ielts_value"/>
                </div>
                <div class="customInput">
                    <label>Admission fee</label>
                    <input type="number"/>
                </div>
                <div class="customInput">
                    <label>GRE/GMAT</label>
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>Tuition cost</label>
                    <input type="number"/>
                </div>
                <div class="customInput">
                    <label>Код программы</label>
                    <input type="text" v-model="formData.code"/>
                </div>
                <div class="customInput">
                    <label>Partnership</label>
                    
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>Entrollment</label>
                    
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>Уровень</label>
                    
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>Deadline 1</label>
                    <input type="date" placeholder="..">
                </div>
                <div class="customInput">
                    <label>Deadline 2</label>
                    <input type="date" placeholder="..">
                </div>
                <div class="customInput">
                    <label>Выберите страну</label>
                    
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>State / province</label>
                    <customSelect />
                </div>
                <div class="customInput">
                    <label>City</label>
                    <customSelect />
                </div>
            </div>
            <p class="customTitle">Медиа файлы программы</p>
            <div class="modal1__files">
                <div class="modal1__files__in" v-for="item in 3" :key="item">
                    <img src="@/assets/icons/program.png"/>
                </div>
                <label class="modal1__files__plus">
                    <input type="file" style="display: none;"/>
                    <img src="@/assets/icons/plus.svg"/>
                    <p>Добавить</p>
                </label>
            </div>
            <p class="customTitle">Описание программы</p>
            <div class="modal1__textarea">
                <textarea placeholder="..."></textarea>
            </div>
            <div class="modal1__documents">
                <div class="customInput">
                    <label>Академические требования</label>
                    <textarea>Список документов</textarea>
                </div>
                <div class="customInput">
                    <label>Перечень документов</label>
                    <textarea>Список документов</textarea>
                </div>
            </div>
            <div class="modal1__buttons">
                <button class="modal1__buttons__save">Опубликовать</button>
                <button class="modal1__buttons__delete">Предпросмотр</button>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from '../customSelect.vue';
import axios from "axios";
export default {
    data(){
        return{
            categories: [],
            subCategories: [],
            countries: [],
            steps: [],
            stips: [],
            formData: {
                program_name: '',
                scholarship_id: null,
                study_degree_id:null,
                gpa: null,
                ielts_value: null,
                gre: null,
                code: null,
            }
        }
    },
    components: {
        customSelect
    },
    methods: {
        async getPage() {
            await axios.get(`/platform/scholarships`, {
            })
                .then(res => {
                    this.stips = res.data.data
                })
                .catch(err => {
                })
                await axios.get(`/platform/countries`, {
            })
                .then(res => {
                    this.countries = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/study-degrees`, {
            })
                .then(res => {
                    this.steps = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/categories`, {
            })
                .then(res => {
                    this.categories = res.data.data
                })
                .catch(err => {
                })
        },
        closeModal(){
            this.$emit('closeModal')
        }
    },
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
.modal1Back{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
    background-color: #0000007a;
}
.modal1{
    width: 64%;
    background: white;
    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)60px;
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    .exit{
        position: absolute;
        top: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        cursor: pointer;
        right: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        filter: brightness(0) saturate(100%);
        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    }
    &__top{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 100%;
        &__title{
            color: #11415D;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            font-weight: 600;
        }
        &__sub{
            color: #0000008A;
        }
    }
    &__inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    }
    &__files{
        display: flex;
        gap: 1%;
        align-items: center;
        &__in{
            width: 10%;
            img{
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }
        }
        &__plus{
            width: 10%;
            border-radius: 10px;
            cursor: pointer;
            color: #0000008A;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: #F0F2F5;
            align-items: center;
            justify-content: center;
            img{
                width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
            }
        }
    }
    &__documents{
        display: flex;
        justify-content: space-between;
        .customInput{
            width: 48%;
        }
    }
    &__textarea{
        width: 100%;
        textarea{
            width: 100%;
            height: 250px;
            resize: none;
            border: 1px solid #0000001A;
            border-radius: 10px;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }
    }
    &__buttons{
        display: flex;
        gap: 2%;
        button{
            width: 30%;
            border: none;
            height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
}
.customTitle{
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
    font-weight: 600;
}
.customInput{
    position: relative;
    display: inline-block;
    width: 32%;
    cursor: pointer !important;
    label{
        position: absolute;
        top: -8px;
        left: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        color: #c0c0c0; 
        z-index: 98;
    }
    input, select {
        width: 100%;
        padding: 0px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
    }
    div{
        width: 100%;
        resize: none;
        border-radius: 10px;
    }
    textarea{
        width: 100%;
        border: 1px solid #00000010;
        height: 300px;
        resize: none;
        border-radius: 10px;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    }
    img{
        position: absolute;
        left: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        top: 50%;
        width: 5%;
        height: 10px;
        transform: translate(-50%, -50%);
        filter: brightness(0) saturate(100%) invert(46%) sepia(75%) saturate(3165%) hue-rotate(213deg) brightness(100%) contrast(103%);
    }
    }
</style>