<template>
    <addNews v-if="openAdd" @closeModal="openAdd = false"/>
    <div class="mainStyle3">
        <div class="mainStyle3__left">
            <platformTable />
        </div>
        <div class="mainStyle3__right">
            <div class="mainStyle3__right__top">
                <div class="mainStyle3__right__top__left">
                    <p class="mainStyle3__right__top__left__text">Поиск программ ></p>
                    <p class="mainStyle3__right__top__left__title">Новости</p>
                    <button @click="openAdd = true">
                        <p>Добавить новость</p>
                        <img src="@/assets/icons/add.svg"/>
                    </button>
                </div>
                <div class="mainStyle3__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="news">
                <div class="news__in" v-for="(item, index) in 5" :key="index">
                    <img :src="require('@/assets/icons/news' + (index) + '.svg')"/>
                    <p>Название новости</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import AboutProgram from '@/components/newPlatform/aboutProgram.vue';
import customSelect from '@/components/customSelect.vue';
import addNews from '@/components/addNews.vue';
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        AboutProgram,
        customSelect,
        addNews
    },
    data() {
        return {
            openAdd: false
        }
    }
}
</script>
<style lang="scss" scoped>
    .news{
        display: flex;
        flex-wrap: wrap;
        gap: 3.5%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__in{
            cursor: pointer;
            width: 31%;
            border-radius: 10px;
            background-color: white;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            img{
                width: 100%;
                aspect-ratio: 1.83;
                object-fit: cover;
            }
        }
    }
</style>