<template>
    <EditCurator v-if="openEdit" @closeModal="openEdit = false"/>
    <AddPerson v-if="openAdd" @closeModal="openAdd = false"  :type="'new'"/>
    <div class="mainStyle3">
        <div class="mainStyle3__left">
            <platformTable />
        </div>
        <div class="mainStyle3__right">
            <div class="mainStyle3__right__top">
                <div class="mainStyle3__right__top__left">
                    <p class="mainStyle3__right__top__left__title">Пользователи</p>
                    <p style="color: #0000008A;">13445 пользователей</p>
                </div>
                <div class="mainStyle3__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="block">
                <div class="block__top">
                    <div class="block__top__left">
                        <div class="block__top__left__icon">
                            <img src="@/assets/icons/users.svg"/>
                            <p>Пользователи</p>
                        </div>
                        <button @click="openAdd =true">
                            <img src="@/assets/icons/plus.svg"/>
                            <p>Действие</p>
                        </button>
                    </div>
                    <div class="block__top__right">
                        <div class="block__top__right__search">
                            <input type="search" placeholder="Поиск по ФИО" />
                            <img src="@/assets/icons/find.svg" />
                        </div>
                        <div class="block__top__right__filter" @click="openFilter = !openFilter"
                            :class="{ customFilter: openFilter }">
                            <p>Фильтр</p>
                            <img src="@/assets/icons/filter.svg" />
                        </div>
                        <div class="block__top__right__select">
                            <p>Сортировка:</p>
                            <customSelect :is-border="true" />
                        </div>
                    </div>
                </div>
                <div class="selects" v-if="openFilter">
                    <div class="selects__top">
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                    </div>
                    <div class="selects__bot">
                        <button class="selects__bot__save">Применить</button>
                        <button class="selects__bot__delete">Сбросить</button>
                    </div>
                </div>
                <div class="customTable">
                    <div class="customTable__left">
                        <div class="customTable__left__top">
                            <input type="checkbox" />
                            <p>Имя пользователя</p>
                        </div>
                        <div class="customTable__left__bot" v-for="item in 6">
                            <input type="checkbox" />
                            <div class="customTable__left__bot__person">
                                <img src="@/assets/icons/person.svg" />
                                <div class="customTable__left__bot__person__info">
                                    <div class="customTable__left__bot__person__info__id">
                                        <p>ID: 896879</p>
                                        <div class="new">NEW</div>
                                    </div>
                                    <div class="customTable__left__bot__person__info__name">Анна Дублина</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 1">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__status">Статус клиента</p>
                            <p class="customTable__right__top__progress">Прогресс бар</p>
                            <p class="customTable__right__top__et1">Этап</p>
                            <p class="customTable__right__top__ielts">IELTS</p>
                            <p class="customTable__right__top__risk">С рисками</p>
                            <img src="@/assets/icons/blackSelect.svg" @click="opentable = 2" class="customTable__right__select"/>
                        </div>
                        <div class="customTable__right__bot" v-for="item in 6">
                            <div class="customTable__right__bot__status">Истек</div>
                            <div class="customTable__right__bot__progress">%</div>
                            <div class="customTable__right__bot__et1">Значение</div>
                            <div class="customTable__right__bot__ielts">Значение</div>
                            <div class="customTable__right__bot__risk">Значение</div>
                            <div class="customTable__right__bot__img"></div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 2">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__year">Год поступления</p>
                            <p class="customTable__right__top__packet">Пакет</p>
                            <p class="customTable__right__top__curator">Куратор</p>
                            <img src="@/assets/icons/blackSelect.svg" style="rotate: 180deg;" @click="opentable = 1" class="customTable__right__select"/>
                        </div>
                        <div class="customTable__right__bot" v-for="item in 6">
                            <div class="customTable__right__bot__year">Значение</div>
                            <div class="customTable__right__bot__packet">Значение</div>
                            <div class="customTable__right__bot__curator">Значение</div>
                            <div class="customTable__right__bot__img"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import EditCurator from "@/components/newPlatform/editCurator.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import customSelect from "@/components/customSelect.vue";
import AddPerson from "@/components/addPerson.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        customSelect,
        userInfo,
        AddPerson,
        EditCurator
    },
    data() {
        return {
            openEdit: false,
            opentable: 1,
            openFilter: false,
            openAdd: false
        };
    },
    methods: {
        saveData(){
            toast.success('Сохранено')
        }
    }
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.customFilter {
    background: #11415D;

    p {
        color: white !important;
    }

    img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(94%) saturate(3%) hue-rotate(179deg) brightness(104%) contrast(100%);
    }
}
.block{
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__left{
            width: 25%;
            display: flex;
            justify-content: space-between;
            &__icon{
                display: flex;
                gap: 10px;
                align-items: center;
            }
            button{
                display: flex;
                gap: 10px;
                align-items: center;
                border-radius: 10px;
                border: 1px solid #0000001A;
                padding: 0 10px;
                background-color: transparent;
                justify-content: center;
                img{
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    aspect-ratio: 1;
                }
            }
        }
        &__right {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            width: 72%;
            gap: 10px;
            justify-content: flex-end;
            align-items: stretch;
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);

            img {
                width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                cursor: pointer;
            }

            &__select,
            &__export {
                max-width: 35%;
                width: fit-content;
            }

            &__search {
                width: 20%;
                border: 1px solid #0000001A;
                padding: 10px;
                border-radius: 10px;
                position: relative;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    right: 5px;
                }

                input {
                    border: none;
                    font-style: italic;
                    width: 100%;
                    height: 100%;
                }
            }

            &__select,
            &__export,
            &__filter {
                display: flex;
                cursor: pointer;
                gap: 5px;
                border-radius: 10px;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #0000001A;
                padding: 0px 10px;

                p {
                    color: #0000008A;
                }

                select {
                    border: none;
                }
            }
        }
    }
}
.selects {
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 100%;

        &__top {
            display: flex;
            flex-wrap: wrap;
            gap: 3.5%;
            row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                width: 31%;
                cursor: pointer;
            }
        }

        &__bot {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

            button {
                width: 20%;
                border: none;
                padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px)0px;
                border-radius: 10px;
            }

            &__save {
                color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #11415D;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #2076a7;
                }
            }

            &__delete {
                background-color: inherit;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                border: 1px solid #0000001A !important;
                color: #0000008A;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }
</style>