<template>
    <div class="mainStyle1">
        <div class="mainStyle1__left">
            <platformTable />
        </div>
        <div class="mainStyle1__right">
            <div class="mainStyle1__right__top">
                <div class="mainStyle1__right__top__left">
                    <p class="mainStyle1__right__top__left__title">Поиск программ</p>
                </div>
                <div class="mainStyle1__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <ProgramsComponent />
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
import customSelect from '@/components/customSelect.vue';
import ProgramsComponent from '@/components/programsComponent.vue';
export default {
    components:{
        platformTable, Swiper, SwiperSlide, newCardComponent,userInfo,customSelect,ProgramsComponent
    },
    data(){
        return{
            activeTab: 'list',
            selectedStip: 0,
            selectedStep: 0,
            selectedCat: -1,
            category:[
                {
                    img: 'category1',
                    name: 'Tech & Policy'
                },
                {
                    img: 'category2',
                    name: 'Fine Arts'
                },
                {
                    img: 'category3',
                    name: 'Engineering'
                },
                {
                    img: 'category4',
                    name: 'Social studies'
                },
                {
                    img: 'category5',
                    name: 'Craftsmanship'
                },
                {
                    img: 'category6',
                    name: 'Marketing'
                },
                {
                    img: 'category7',
                    name: 'Coming soon'
                },
                {
                    img: 'category8',
                    name: 'Показать все'
                },
            ]
        }
    },
    methods: {
        selectStep(index){
            if(this.selectedStep == index){
                this.selectedStep = -1
            }
            else{
                this.selectedStep = index
            }
        },selectStip(index){
            if(this.selectedStip == index){
                this.selectedStip = -1
            }
            else{
                this.selectedStip = index
            }
        },selectCat(index){
            if(this.selectedCat == index){
                this.selectedCat = -1
            }
            else{
                this.selectedCat = index
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.activeTab{
    background-color: white !important;
}
</style>