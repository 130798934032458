<template>
    <div class="mainStyle3">
        <div class="mainStyle3__left">
            <platformTable />
        </div>
        <div class="mainStyle3__right">
            <div class="mainStyle3__right__top">
                <div class="mainStyle3__right__top__left">
                    <p class="mainStyle3__right__top__left__title">Платформа</p>
                </div>
                <div class="mainStyle3__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="cards">
                <div class="cards__inBack" v-for="item in cards" :key="item" @click="$router.push(item.route)">
                    <div class="cards__in" :style="{ backgroundImage: `url(${require('@/assets/icons/' + item.background + '.png')})` }">
                        <img :src="require('@/assets/icons/' + item.icon + '.svg')"/>
                        <p>{{ item.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import CustomSelect from "@/components/customSelect.vue";
import { url } from "@vuelidate/validators";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        CustomSelect
    },
    data() {
        return {
            cards: [
                {
                    background: 'platform_1',
                    icon: 'platformIcon1',
                    name: 'Управление программами',
                    route: '/curator/programs'
                },
                {
                    background: 'platform_2',
                    icon: 'platformIcon2',
                    name: 'Международные гранты',
                    route: '/testPlatform/intGrants'
                },
                {
                    background: 'platform_3',
                    icon: 'platformIcon3',
                    name: 'Языковая и тест. школа',
                    route: '/supervisor/schools'
                },
                {
                    background: 'platform_4',
                    icon: 'platformIcon4',
                    name: 'Премиум опции',
                    route: '/supervisor/premium'
                },
                {
                    background: 'platform_5',
                    icon: 'platformIcon5',
                    name: 'Полезное',
                    route: '/testPlatform/useful'
                },
                {
                    background: 'platform_5',
                    icon: 'platformIcon5',
                    name: 'News',
                    route: '/supervisor/news'
                },
            ]
        };
    },
    methods: {
    }
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.cards{
    display: flex;
    flex-wrap: wrap;
    gap: 3.5%;
    row-gap: 40px;
    &__inBack{
        width: 31%;
        padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        border-radius: 10px;
        background-color: white;
    }
    &__in{
        width: 100%;
        aspect-ratio: 1.8;
        background-size: cover;
        background-position: center;
        position: relative;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        cursor: pointer;
        p{
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            font-weight: 700;
            z-index: 1;
            color: white;
        }
        img{
            z-index: 1;
        }
        &::before{
            content: '';
            position: absolute;
            background-color: #0000008A;
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}
</style>