<template>
    <div class="modal1" @mouseenter="isBig = true" @mouseleave="isBig = false">
        <div class="modal1__mini" v-if="isBig == false">
            <p class="modal1__mini__text">Прогресс менторства</p>
            <p class="modal1__mini__percentage">{{percentage}}% done</p>
        </div>
        <div class="modal1__big" v-else>
            <p class="modal1__big__text">Прогресс менторства</p>
            <div class="modal1__big__progress">
                <p class="modal1__big__progress__title">Выполнено работы</p>
                <div class="modal1__big__progress__block">
                    <div class="modal1__big__progress__block__in" :style="{width: percentage + '%'}">
                    </div>
                </div>
                <p 
                    class="modal1__big__progress__percentage" 
                    :style="{ left: `calc(${percentage}% - 24px)` }">
                    {{ percentage }}%
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['percentage'],
    data(){
        return{
            isBig: false
        }
    }
}
</script>
<style lang="scss" scoped>
.modal1{
    position: fixed;
    bottom: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    right: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    width: fit-content;
    background-color: white;
    box-shadow: 0px 4px 4px 2px #0000001A;
    z-index: 2;
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    height: 1min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    cursor: pointer;
    &__mini{
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        gap: 10px;
        align-items: center;
        &__text{
            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            color: #0000008A;
        }
        &__percentage{
            color: #FD2D2A;
            font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            font-weight: 500;
        }
    }
    &__big{
        width: 600px;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__text{
            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            color: #0000008A;
        }
        &__progress{
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
            p{
                color: #FD2D2A;
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            }
            &__block{
                height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                border: 1px solid #0000001A;
                border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                width: 100%;
                &__in{
                    background-color: #FD2D2A;
                    height: 100%;
                    border-radius: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                }
            }
            &__percentage{
                position: absolute;
                top: 0px;
            }
        }
    }
    @media(max-width:768px){
        display: none;
    }
}
</style>