<template>
    <div class="burger1Back" @click="closeBurger()"> 
        <div class="burger1" @click.stop>
            <div class="burger1__top">
                <img src="@/assets/icons/logo.svg"/>
                <img src="@/assets/icons/logoText.svg"/>
                <div class="burger1__top__bot">
                    <img src="@/assets/icons/basket.svg"/>
                    <img src="@/assets/icons/bell.svg"/>
                </div>  
            </div>
            <div class="burger1__list">
                <div class="burger1__list__in" v-for="item in tabs" :key="item" @click="$router.push(`${item.route}`)">
                    <div class="line"></div>
                    <p>{{ item.name }}</p>
                </div>
                <div class="burger1__list__in" @click="leaveAccount()">
                    <div class="line"></div>
                    <p>Выйти</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    data(){
        return{
            isCurator: true,
            tabsSupervisor: [
            {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route:'/supervisor'
                },
                {
                    img: 'tabc_3',
                    name: 'Пользователи',
                    route:'/supervisor/users'
                },
                {
                    img: 'tab_1',
                    name: 'Кураторы',
                    route:'/supervisor/curators'
                },
                {
                    img: 'tab_8',
                    name: 'Платформа',
                    route:'/supervisor/platform'
                },
                {
                    img: 'tabc_4',
                    name: 'Уведомления',
                    route: '/curator/notifications'
                },
            ],
            tabsCurator: [
                {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route: '/curator'
                },
                {
                    img: 'tabc_2',
                    name: 'Программы',
                    route: '/curator/programs',
                },
                {
                    img: 'tabc_3',
                    name: 'Пользователи',
                    route: '/curator/users'
                },
                {
                    img: 'tabc_4',
                    name: 'Уведомления',
                    route: '/curator/notifications'
                },
            ],
            tabs:[
                {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route: '/testPlatform'
                },
                {
                    img: 'tab_2',
                    name: 'Поиск программ',
                    route: '/testPlatformFind',
                    route1: '/testPlatformFind/1',
                },
                {
                    img: 'tab_3',
                    name: 'Избранное',
                    route: '/testFavourite'
                },
                {
                    img: 'tab_4',
                    name: 'Поступление',
                    route: '/testPlatform/admission'
                },
                {
                    img: 'tab_5',
                    name: 'Международные гранты',
                    route: '/testPlatform/intGrants'
                },
                {
                    img: 'tab_6',
                    name: 'Языковая и тест. школа',
                    route: '/testPlatform/schools'
                },
                {
                    img: 'tab_9',
                    name: 'Премиум опции',
                    route: '/testPlatform/premium'
                },
                {
                    img: 'tab_7',
                    name: 'Полезное',
                    route: '/testPlatform/useful'
                },
            ],
            news: [
                {
                    name: 'Добавлено 120 новых учреждений'
                },
                {
                    name: 'Добавлено 5 новых занятий'
                },
                {
                    name: 'Добавлено 5 новых занятий'
                },
            ]
        }
    },
    methods: {
    ...mapActions(["logoutUser"]),
    closeBurger(){
        this.$emit('closeModal')
    },
    leaveAccount() {
      this.logoutUser();
      toast("Вы вышли с аккаунта.");
      this.$router.push('/login')
    }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #7171717c;
}
    .burger1Back{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
    }
    .burger1{
        width: 80%;
        height: fit-content;
        background: linear-gradient(180deg, #11415dca 0px, #2489c3cf 500px);
        padding: 70px 10px;
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__top{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            &__bot{
                display: flex;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                img{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7470%) hue-rotate(290deg) brightness(112%) contrast(91%);
                }
            }
        }
        &__list{
            display: flex;
            flex-direction: column;
            gap: 10px;
            &__in{
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                color: rgba(255, 255, 255, 0.799);
            }
        }
    }
</style>