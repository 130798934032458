import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";
import { createToaster } from "@meforma/vue-toaster";

export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "ru",
    auth: localStorage.getItem("access_token") ? true : false,
    role: 'user',
    user_id: null,
    curator_id: null,
    user: {},
    cdn: 'https://mentorme-back.test-nomad.kz/'
  },
  getters: {
    userInfo(state) {
      return state.user?.data?.data || false
    }
  },
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    logoutUser({ commit }) {
      localStorage.removeItem("access_token");
      commit("SET_USER", {});
      commit("SET_AUTH", false);
    },
    async requestUser({commit}) {
    await axios.get(`https://mentorme-back.test-nomad.kz/api/platform/me`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    })
    .then(res => {
      console.log(localStorage.getItem("access_token"));
      commit("SET_USER", res);
      commit("SET_AUTH", true);
      this.state.user_id = res.data.data.id;
      if(res.data.data.role.name == 'client') {
        this.state.curator_id = res.data.data.curator_id;
        this.state.role = 'user'
      }
      if(res.data.data.role.name == 'curator'){
        this.state.role = 'curator'
      }
    })

    .catch(error => {
        commit("SET_USER", {});
        commit("SET_AUTH", false);
        localStorage.removeItem("access_token");
    })
  },
  },
  modules: {
  }
})
