<template>
    <div class="mainStyle" :class="{ mainStyle3: $store.state.role == 'supervisor' }">
        <div class="mainStyle__left">
            <platformTable />
        </div>
        <div class="mainStyle__right">
            <div class="mainStyle__right__top">
                <div class="mainStyle__right__top__left" v-if="isInAdmission">
                    <p>Пользователи > {{ userData.name }} > <span>Поступление ВУЗ</span></p>
                </div>
                <div class="mainStyle__right__top__left" v-else>
                    <p style="color: #0000008A;">Пользователи ></p>
                    <p class="mainStyle__right__top__left__title">{{ userData.name }}</p>
                </div>
                <div class="mainStyle__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="userCard">
                <div class="userCard__customTop" v-if="isInAdmission">
                    <div class="userCard__customTop__left">
                        <img src="@/assets/icons/univer.svg" />
                        <div class="userCard__customTop__left__right">
                            <p class="userCard__customTop__left__right__name bold">Massachusetts Institute of Technology
                            </p>
                            <p class="userCard__customTop__left__right__email grey">Massachusets, USA</p>
                        </div>
                    </div>
                    <div class="userCard__customTop__export">
                        <img src="@/assets/icons/export.svg" />
                        <p>Экспортировать данные</p>
                    </div>
                </div>
                <div class="userCard__top" v-else>
                    <img :src="userData.image_url" v-if="userData.image_url" />
                    <img src="@/assets/icons/profile.webp" v-else />
                    <div class="userCard__top__right">
                        <p class="userCard__top__right__name bold">{{ userData.name }}</p>
                        <p class="userCard__top__right__email grey">{{ userData.id }}</p>
                    </div>
                </div>
                <div class="userCard__tabs">
                    <div class="userCard__tabs__in" v-for="(item, index) in tabs" :key="item"
                        @click="activeTab = index, isInAdmission = false">
                        <div class="userCard__tabs__in__name" :class="{ activeTab: index == activeTab }">{{ item.name }}
                        </div>
                        <div class="line2"></div>
                    </div>
                </div>
                <div class="main" v-if="activeTab == 0 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Договор</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Статус договора</label>
                            <customSelect :list="contractStatusses" v-model="contStatusId" />
                        </div>
                        <div class="customInputWhite">
                            <label>Номер договора</label>
                            <input placeholder="Не выбрано" v-model="contractNumber" />
                        </div>
                        <div class="customInputWhite">
                            <label>Дата заключения договора</label>
                            <input type="date" v-model="contractDate" />
                        </div>
                        <div class="customInputWhite">
                            <label>Количество дней договора</label>
                            <input placeholder=".." v-model="countDays" />
                        </div>
                        <div class="customInputWhite">
                            <label>Подача</label>
                            <customSelect :list="supplyList" v-model="supplyId" />
                        </div>
                        <div class="customInputWhite">
                            <label>Статус клиента</label>
                            <customSelect :list="clientStatusses" v-model="clientStatusId" />
                        </div>
                        <div class="customInputWhite">
                            <label>Желаемая степень</label>
                            <customSelect :list="steps" v-model="stepId" />
                        </div>
                        <div class="customInputWhite">
                            <label>Год поступления</label>
                            <input type="text" disabled placeholder="Выбирается супервайзером" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Ключевые сроки” > GENERAL ENGLISH</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="newCources()">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs1" v-for="item in keyCources" :key="item">
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="item.title" />
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" v-model="item.deadline" />
                        </div>
                        <div class="main__inputs__delete" @click="deleteGeneral(item.id)">
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="main__inputs__select">
                            <customSelect :list="courseStatusses" v-model="item.status" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Ключевые сроки” > Тесты</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="newTests()">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs1" v-for="item in keyTests" :key="item">
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="item.title" />
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" v-model="item.deadline" />
                        </div>
                        <div class="main__inputs__delete" @click="deleteTest(item.id)">
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="main__inputs__select">
                            <customSelect :list="courseStatusses" v-model="item.status" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Индивидуальный план”</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Индивидуальный план 1</label>
                            <input type="text" v-model="individuals[0].hyperlink" :placeholder="individulasFromBack[0]?.hyperlink" />
                        </div>
                        <div class="customInputWhite">
                            <label>Индивидуальный план 2</label>
                            <input type="text" :placeholder="individulasFromBack[1]?.hyperlink" v-model="individuals[1].hyperlink" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Международная стипендия” </p>
                    </div>
                    <div class="main__inputs1">
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="intSch[0].title" />
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" v-model="intSch[0].date" />
                        </div>
                        <div class="customInputWhite">
                            <label>Название элемента</label>
                            <input v-model="intSch[1].title" @change="handleChange()"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Срок</label>
                            <input type="date" v-model="intSch[1].date" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Виджет “Риски”</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Риски пользователя</label>
                            <customSelect :list="newRisks" v-model="newRisk" :is-filter="true"/>
                        </div>
                    </div>
                    <div class="main__risks">
                        <div class="main__risks__in" v-for="item in risks" @click="deleteRisk(item.id)" :key="item">
                            <img src="@/assets/icons/exit.svg" />
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                    <div class="main__risk">
                        <input placeholder="Введите название риска..." v-model="newRisk" />
                        <button @click="newRisk1()">Добавить новый риск</button>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }"
                            @click="sendMain()">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="general" v-if="activeTab == 1 && isInAdmission == false">
                    <AddProduct v-if="openAdd" @closeModal="openAdd = false" :is-add="true" />
                    <AddProduct v-if="openRedactor" @closeModal="openRedactor = false" :is-redactor="true" />
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Личная информация</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="general__inputs">
                        <div class="customInputWhite">
                            <label>Фамилия</label>
                            <input v-model="editUser.surname" :placeholder="userData.surname" />
                        </div>
                        <div class="customInputWhite">
                            <label>Имя</label>
                            <input v-model="editUser.name" :placeholder="userData.name" />
                        </div>
                        <div class="newcustomInputWhite">
                            <label>ИИН</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.iin" :placeholder="userData.iin" />
                                <div class="line1"></div>
                                <p v-if="userData.iin_status == 0" @click="confirmGeneral('iin_status')">Подтвердить</p>
                                <p v-if="userData.iin_status == 1">Подтверждено</p>
                            </div>
                        </div>
                        <div class="customInputWhite">
                            <label>Дата рождения</label>
                            <input type="date" v-model="editUser.birthday" :placeholder="userData.birthday" />
                        </div>
                        <div class="customInputWhite">
                            <label>Страна</label>
                            <customSelect :list="countries" @change="cityList()" v-model="selectedCountry"/>
                        </div>
                        <div class="customInputWhite">
                            <label>Город</label>
                            <customSelect :list="cities" v-model="editUser.city_id" />
                        </div>
                        <div class="newcustomInputWhite">
                            <label>Телефон</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.phone" :placeholder="userData.phone" />
                                <div class="line1"></div>
                                <p v-if="userData.phone_status == 0" @click="confirmGeneral('phone_status')">Подтвердить
                                </p>
                                <p v-if="userData.phone_status == 1">Подтверждено</p>
                            </div>
                        </div>
                        <div class="newcustomInputWhite">
                            <label>Почта</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.email" :placeholder="userData.email" />
                                <div class="line1"></div>
                                <p v-if="userData.email_status == 0" @click="confirmGeneral('email_status')">Подтвердить
                                </p>
                                <p v-if="userData.email_status == 1">Подтверждено</p>
                            </div>
                        </div>
                        <div class="newcustomInputWhite">
                            <label>Телеграм</label>
                            <div class="newcustomInputWhite__in">
                                <input v-model="editUser.telegram" :placeholder="userData.telegram" />
                                <div class="line1"></div>
                                <p v-if="userData.telegram_status == 0" @click="confirmGeneral('telegram_status')">
                                    Подтвердить</p>
                                <p v-if="userData.telegram_status == 1">Подтверждено</p>
                            </div>
                        </div>
                        <div class="customInputWhite">
                            <label>Пол</label>
                            <customSelect :list="genders" v-model="editUser.gender" />
                        </div>
                        <div class="customInputWhite">
                            <label>Адрес</label>
                            <input v-model="editUser.address" :placeholder="userData.address" />
                        </div>
                        <div class="customInputWhite">
                            <label>Id</label>
                            <input v-model="editUser.id" :placeholder="userData.id" disabled/>
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Ваши покупки</p>
                    </div>
                    <div class="general__buys">
                        <div class="general__buys__in" v-for="item in userBuys" :key="item">
                            <div class="general__buys__in__edit">
                                <img src="@/assets/icons/redactor.svg" @click="openRedactor = true" />
                                <div class="line1"></div>
                                <img src="@/assets/icons/basketGrey.svg" @click="deleteBuy(item.id)" />
                            </div>
                            <p class="general__buys__in__title" v-if="item.type == 1">Курсы</p>
                            <p class="general__buys__in__title" v-if="item.type == 2">Премиум опция</p>
                            <div class="line"></div>
                            <img :src="item?.course?.image" v-if="item?.course?.image"/>
                            <img :src="item?.premium_option?.icon" v-if="item?.premium_option?.icon"/>
                            <p class="general__buys__in__name">{{ item?.typeText }}</p>
                            <p class="general__buys__in__sub">{{ item?.statusText }}</p>
                        </div>
                        <div class="general__buys__plus" @click="openAdd = true">
                            <img src="@/assets/icons/plus.svg" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Прогресс по клиенту</p>
                    </div>
                    <div class="general__progress">
                        <div class="general__progress__in">
                            <div class="customInputWhite">
                                <label>Введите процент</label>
                                <input v-model="process" />
                            </div>
                            <button @click="sendProcess()">Применить</button>
                        </div>
                        <div class="general__progress__in">
                            <div class="customInputWhite">
                                <label>Выберите этап</label>
                                <customSelect :list="stages" v-model="selectedStage" />
                            </div>
                            <button @click="sendProcess()">Применить</button>
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }"
                            @click="sendData()">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="document" v-if="activeTab == 3 && isInAdmission == false">
                    <AddFile v-if="openFile" @closeModal="openFile = false" />
                    <p class="document__title">Файлы и документы</p>
                    <div class="document__block">
                        <div class="document__block__in" v-for="item in documents" :key="item.id">
                            <img class="document__block__in__delete" src="@/assets/icons/delete.svg" />
                            <div class="document__block__in__left">
                                <img src="@/assets/icons/cvType.svg" v-if="item?.field == 'cv'"/>
                                <img src="@/assets/icons/otherType.svg" v-else/>
                            </div>
                            <div class="line2"></div>
                            <div class="document__block__in__right">
                                <a :href="item.value" class="document__block__in__right__pdf"
                                    :class="{ customGreen: item?.status == 1, notApproved: item?.status == 2 }">link
                                </a>
                                <p class="document__block__in__right__name"
                                    :class="{ customGreen: item?.status == 1, notApproved: item?.status == 2 }">{{
                                        item?.field }}</p>
                                <div class="document__block__in__right__undefined" v-if="item?.status == 0">
                                    <p class="document__block__in__right__undefined__left"
                                    >
                                        Approve</p>
                                    <div class="line2"></div>
                                    <p class="document__block__in__right__undefined__right"
                                        >Not yet</p>
                                </div>
                                <div class="document__block__in__right__approved" v-if="item?.status == 1">Approved
                                </div>
                                <div class="document__block__in__right__not" v-if="item?.status == 2">Not yet</div>
                            </div>
                        </div>
                        <div class="document__block__plus" @click="openFile = true">
                            <img src="@/assets/icons/plus.svg" />
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="favourite" v-if="activeTab == 4 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Избранные программы клиента</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="sendSuper"
                                v-if="$store.state.role == 'curator'">
                                <p>Отправить супервайзеру</p>
                            </div>
                            <div class="customTabTitle__right__check" v-if="$store.state.role == 'supervisor'">
                                <span>Утвердить</span> / Отклонить
                            </div>
                        </div>
                    </div>
                    <div class="favourite__block">
                        <div class="favourite__block__in" v-for="item in favourites" :key="item">
                            <NewCardComponent :item="item" :user-id="52"
                                @newAdmission="getAdmissions(), getFavourites()" />
                        </div>
                    </div>
                    <!-- <div class="main__buttons">
                        <button class="main__buttons__save" :class="{supervisorButton: $store.state.role == 'supervisor'}">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div> -->
                </div>
                <div class="admission" v-if="activeTab == 5 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Выберите ВУЗ для детального редактирования</p>
                    </div>
                    <div class="admission__block">
                        <div class="admission__block__in" v-for="item in admissionPrograms" :key="item">
                            <NewCardComponent :is-admission="true" :item="item.university_program"
                                @changeAdmission="changeProgramm(item.id, item)" />
                        </div>
                    </div>
                </div>
                <div class="general" v-if="activeTab == 2 && isInAdmission == false">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Академические результаты</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="result__inputs">
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="school">Школа</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="school" v-model="results.school">
                                    <div class="line1"></div>
                                    <p v-if="results.status_school == 0" @click="confirmResult('status_school')">
                                        Подтвердить</p>
                                    <p v-if="results.status_school == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- Углубленное изучение -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="studyField">Углубленное изучение</label>
                                <div class="newcustomInputWhite__in">
                                    <customSelect v-model="results.depth_study" :list="depthSudy" class="customSelectClass" isBorder/>
                                    <div class="line1"></div>
                                    <p v-if="results.status_depth_study == 0"
                                        @click="confirmResult('status_depth_study')">Подтвердить</p>
                                    <p v-if="results.status_depth_study == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- Средний балл аттестата -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="averageScore">Средний балл аттестата</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="averageScore" v-model="results.gpa_school">
                                    <div class="line1"></div>
                                    <p v-if="results.status_gpa_school == 0"
                                        @click="confirmResult('status_gpa_school')">Подтвердить</p>
                                    <p v-if="results.status_gpa_school == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>
                        <!-- Placement Test Level -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="placementLevel">Placement Test Level</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="placementLevel" v-model="results.placement_test_level">
                                    <div class="line1"></div>
                                    <p
                                        @click="confirmResult('status_placement_test_level')">Подтвердить</p>
                                    <p v-if="results.status_placement_test_level == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- Placement Test Date -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="placementDate">Placement Test Date</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="date" id="placementDate" v-model="results.placement_test_date">
                                    <div class="line1"></div>
                                    <p v-if="results.status_placement_test_date == 0"
                                        @click="confirmResult('status_placement_test_date')">Подтвердить</p>
                                    <p v-if="results.status_placement_test_date == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- IELTS/TOEFL -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="ielts">IELTS/TOEFL</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="ielts" v-model="results.ielts">
                                    <div class="line1"></div>
                                    <p v-if="results.status_ielts == 0" @click="confirmResult('status_ielts')">
                                        Подтвердить</p>
                                    <p v-if="results.status_ielts == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>

                        <!-- IELTS/TOEFL Date -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="ieltsDate">IELTS/TOEFL Date</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="date" id="ieltsDate" v-model="results.ielts_date">
                                    <div class="line1"></div>
                                    <p v-if="results.status_ielts_date == 0"
                                        @click="confirmResult('status_ielts_date')">Подтвердить</p>
                                    <p v-if="results.status_ielts_date == 1">Подтверждено</p>
                                </div>
                            </div>
                        </div>
                        <!-- SAT -->
                        <div class="result__inputs__in">
                            <div class="newcustomInputWhite">
                                <label for="sat">SAT</label>
                                <div class="newcustomInputWhite__in">
                                    <input type="text" id="sat" v-model="results.sat">
                                    <div class="line1"></div>
                                    <p v-if="results.status_sat == 0" @click="confirmResult('status_sat')">Подтвердить
                                    </p>
                                    <p v-if="results.status_sat == 1">Подтверждено</p>
                                </div>
                            </div>
                            </div>
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="bachelorGPA">GPA Бакалавриат</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="bachelorGPA" v-model="results.gpa_bachelor">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gpa_bachelor == 0"
                                            @click="confirmResult('status_gpa_bachelor')">Подтвердить</p>
                                        <p v-if="results.status_gpa_bachelor == 1">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- GPA Магистратура -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="masterGPA">GPA Магистратура</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="masterGPA" v-model="results.gpa_master">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gpa_master == 0"
                                            @click="confirmResult('status_gpa_master')">Подтвердить</p>
                                        <p v-if="results.status_gpa_master == 1">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- KazTest/Qazresmi -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="kazTest">KazTest/Qazresmi</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="kazTest" v-model="results.kaz_test">
                                        <div class="line1"></div>
                                        <p v-if="results.status_kaz_test == 0"
                                            @click="confirmResult('status_kaz_test')">Подтвердить</p>
                                        <p v-if="results.status_kaz_test == 1">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- KazTest/Qazresmi Date -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="kazTestDate">KazTest/Qazresmi Date</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="date" id="kazTestDate" v-model="results.kaz_test_date">
                                        <div class="line1"></div>
                                        <p v-if="results.status_kaz_test_date == 0"
                                            @click="confirmResult('status_kaz_test_date')">Подтвердить</p>
                                        <p v-if="results.status_kaz_test_date == 1">Подтверждено</p>
                                    </div>
                                </div>
                            </div>
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="gre">GRE/GMAT</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="text" id="gre" v-model="results.gre">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gre == 0" @click="confirmResult('status_gre')">
                                            Подтвердить</p>
                                        <p v-if="results.status_gre == 1">Подтверждено</p>
                                    </div>
                                </div>
                            </div>

                            <!-- GRE/GMAT Date -->
                            <div class="result__inputs__in">
                                <div class="newcustomInputWhite">
                                    <label for="greDate">GRE/GMAT Date</label>
                                    <div class="newcustomInputWhite__in">
                                        <input type="date" id="greDate" v-model="results.gre_date">
                                        <div class="line1"></div>
                                        <p v-if="results.status_gre_date == 0"
                                            @click="confirmResult('status_gre_date')">Подтвердить</p>
                                        <p v-if="results.status_gre_date == 1">Подтверждено</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save"
                            :class="{ supervisorButton: $store.state.role == 'supervisor' }">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="inAdmission" v-if="isInAdmission">
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Договор</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInputWhite">
                            <label>Поступление</label>
                            <customSelect :list="stips" v-model="stipId" />
                        </div>
                        <div class="customInputWhite">
                            <label>Приоритет</label>
                            <customSelect :list="priority" v-model="statusDate.priority" />
                        </div>
                        <div class="customInputWhite">
                            <label>Статус заявки</label>
                            <customSelect :list="statusses" v-model="statusDate.status" />
                        </div>
                        <div class="customInputWhite">
                            <label>Вид оффера</label>
                            <customSelect :list="offers" v-model="statusDate.type_offer" />
                        </div>
                        <div class="customInputWhite">
                            <label>Deadline 1</label>
                            <input type="date" v-model="statusDate.first_date" />
                        </div>
                        <div class="customInputWhite">
                            <label>Deadline 2</label>
                            <input type="date" v-model="statusDate.second_date" />
                        </div>
                    </div>
                    <div class="customTabTitle">
                        <p class="customTabTitle__left">Сроки поступления и услуг</p>
                        <div class="customTabTitle__right">
                            <div class="customTabTitle__right__add" @click="newDeadline()">
                                <img src="@/assets/icons/plus.svg" />
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs" v-for="item in deadlines" :key="item">
                        <div class="customInputWhite">
                            <label>Вид элемента</label>
                            <customSelect :list="documentTypes" v-model="item.document_type" />
                        </div>
                        <div class="customInputWhite" style="width: 20%;">
                            <label>Дата начала</label>
                            <input type="date" v-model="item.date_from" />
                        </div>
                        <div class="customInputWhite" style="width: 20%;">
                            <label>Дата завершения</label>
                            <input type="date" v-model="item.date_to" />
                        </div>
                        <div class="customInputWhite">
                            <label>Статус услуги</label>
                            <customSelect :list="statusses" v-model="item.status" />
                        </div>
                        <div class="main__inputs__delete" @click="deleteDocs(item.document_type)">
                            <img src="@/assets/icons/delete.svg" />
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save" @click="sendDeadline()">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewCardComponent from "@/components/newCardComponent.vue";
import AddProduct from "@/components/newPlatform/addProduct.vue";
import AddFile from "@/components/newPlatform/addFile.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import axios from "axios";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        NewCardComponent,
        AddProduct,
        AddFile,
        customSelect
    },
    data() {
        return {
            depthSudy: [
                {
                    id: 0,
                    title: 'Физика'
                },
                {
                    id: 1,
                    title: 'Математика'
                },
            ],
            intSch: [
                {
                    title: '',
                    date: '',
                    id: '',
                    user_id: ''
                },
                {
                    title: '',
                    date: '',
                    id: '',
                    user_id: ''
                },
            ],
            contStatusId: null,
            clientStatusId: null,
            supplyId: null,
            supplyList: [
                {
                    id: 0,
                    title: 'Подача'
                },
                {
                    id: 1,
                    title: 'Переподача'
                }
            ],
            contractStatusses: [
                {
                    id: 0,
                    title: 'Действует'
                },
                {
                    id: 1,
                    title: 'Истек'
                },
            ],
            clientStatusses: [
                {
                    id: 0,
                    title: 'Активный'
                },
                {
                    id: 1,
                    title: 'Неактивный'
                },
                {
                    id: 2,
                    title: 'Проблемный'
                },
            ],
            InternationalSch: {
                title1: '',
                title2: '',
                date1: '',
                date2: ''
            },
            statusses: [
                {
                    id: 0,
                    title: 'в процессе'
                },
                {
                    id: 1,
                    title: 'принято'
                },
                {
                    id: 2,
                    title: 'отклонено'
                },
            ],
            tabs: [
                {
                    name: 'Главное'
                },
                {
                    name: 'Общее'
                },
                {
                    name: 'Результаты'
                },
                {
                    name: 'Документы'
                },
                {
                    name: 'Избранное'
                },
                {
                    name: 'Поступление'
                },
            ],
            cities: [
                {
                    title: 'Almaty',
                    id: 0
                },
                {
                    title: 'Astana',
                    id: 1
                },
                {
                    title: 'Shymkent',
                    id: 2
                },
            ],
            genders: [
                {
                    title: 'Мужчина',
                    id: 0
                },
                {
                    title: 'Женщина',
                    id: 1
                },
            ],
            editUser: '',
            stages: [
                {
                    title: '1',
                    id: 1
                },
                {
                    title: '2',
                    id: 2
                },
                {
                    title: '3',
                    id: 3
                },
                {
                    title: '4',
                    id: 4
                },
            ],
            courseStatusses: [
                {
                    id: 0,
                    title: 'Завершен'
                },
                {
                    id: 1,
                    title: 'В процессе'
                },
                {
                    id: 2,
                    title: 'Не начато'
                },
            ],
            offers: [
                {
                    title: 'Conditional',
                    id: 0
                }
            ],
            priority: [
                {
                    title: '1',
                    id: 1,
                },
                {
                    title: '2',
                    id: 2,
                },
                {
                    title: '3',
                    id: 3,
                },
                {
                    title: '4',
                    id: 4,
                },
            ],
            selectedStage: '',
            contractDate: null,
            contractNumber: null,
            countDays: null,
            activeTab: 0,
            isInAdmission: false,
            programId: '',
            openAdd: false,
            openRedactor: false,
            openFile: false,
            userData: '',
            stips: [],
            steps: [],
            countries: [],
            cities: [
            ],
            stepId: null,
            statusDate: {
                priority: '',
                status: '',
                type_offer: '',
                first_date: '',
                second_date: ''
            },
            stipId: '',
            risks: false,
            documents: '',
            newRisk: '',
            keyCources: '',
            deadlines: [],
            keyTests: '',
            process: '',
            userBuys: [],
            results: false,
            favourites: false,
            selectedCountry: '',
            documentTypes: [],
            individulasFromBack: [],
            newRisks: [],
            isChanged: false,
            individuals: [
                {
                    user_id: 52,
                    hyperlink: ''
                },
                {
                    user_id: 52,
                    hyperlink: ''
                }
            ],
            selectedId: 0,
            admissionPrograms: false,
            documents: [
            ],
        };
    },

    methods: {
        handleChange() {
            this.isChanged = true;
        },
        deleteBuy(id) {
            axios.delete(`/platform/curator/users/purchase/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    toast.success('Успешно удалено!')
                    this.getBuys
                })
                .catch(err => {
                })
        },
        deleteDocs(id) {
            axios.delete(`/platform/curator/delete-user-documents/${this.programId}?document_type=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    toast.success('Успешно удалено!')
                    this.getPage()
                })
                .catch(err => {
                })
        },
        changeProgramm(id, item) {
            this.isInAdmission = true
            this.programId = id
            if (item.admission_status) {
                this.statusDate = item.admission_status
            }
            if (item.docs) {
                this.deadlines = item.docs.map(doc => {
                    // Заменяем поле document_type_id на document_type
                    const { document_type_id, ...rest } = doc;
                    return {
                        ...rest,
                        document_type: document_type_id // заменяем на document_type
                    };
                });
            }
        },
        newDeadline() {
            if (Array.isArray(this.deadlines)) {
                this.deadlines.push({
                    user_admission_id: this.programId,
                    document_type: '',
                    status: 0,
                    date_from: '',
                    date_to: ''
                });
            } else {
                console.error('deadlines is not an array:', this.deadlines);
            }
        },
        newRisk1() {
            if (this.newRisk != '') {
                const risks = {
                    data: [
                        {
                            user_id: 52,
                            title: this.newRisk
                        }
                    ]
                }
                axios.post('/platform/curator/users-risks', risks, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                }).then((response) => {
                    if (response.status === 201) {
                        toast.success('Успешно!')
                        this.newRisk = ''
                        this.getPage()
                    } else {
                        console.log(response.data);
                    }
                })
                    .catch((response) => {
                        console.log(response.response.data.errors);
                    });
            }
            else {
                toast.error('Пусто')
            }
        },
        async cityList() {
            await axios.get(`/platform/cities?country_id=${this.selectedCountry}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.cities = res.data.data
                })
                .catch(err => {
                })
        },
        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            const day = d.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        newCources() {
            this.keyCources.push({
                title: '',
                deadline: '',
                status: '',
                user_id: 52
            });
        },
        newTests() {
            this.keyTests.push({
                title: '',
                deadline: '',
                status: 0,
                user_id: 52
            });
        },
        sendMain() {
            const schData1 = {
                user_id: 52,
                title: this.intSch[0].title,
                date: this.formatDate(this.intSch[0].date),
                id: this.intSch[0].id
            }
            const schData2 = {
                user_id: 52,
                title: this.intSch[1].title,
                date: this.formatDate(this.intSch[1].date),
                id: this.intSch[1].id
            }
            const formattedData = this.keyCources.map(item => ({
                ...item,
                deadline: this.formatDate(item.deadline) // Format the deadline here
            }));

            const data = {
                data: formattedData
            };
            const formattedData1 = this.keyTests.map(item => ({
                ...item,
                deadline: this.formatDate(item.deadline) // Format the deadline here
            }));
            const data1 = {
                data: formattedData1
            };
            const agree = {
                user_id: 52,
                study_degree_id: this.stepId,
                send_date: this.formatDate(this.contractDate),
                contract_number: this.contractNumber,
                supply: this.supplyId,
                count_of_days: this.countDays,
                status: this.contStatusId,
                user_status: this.clientStatusId
            }
            const indiv = {
            data: this.individuals.map((individual, index) => {
                if (individual.hyperlink) {
                    individual.link = individual.hyperlink;
                    delete individual.hyperlink;
                }
                if (!individual.user_id) {
                    individual.user_id = 52;
                }
                console.log(this.individulasFromBack);
                
                if (this.individulasFromBack && this.individulasFromBack[index]) {
                    individual.old_link = this.individulasFromBack[index].hyperlink;
                }
                return individual;
            })
            };
            const schData = {
                data: [
                    {
                        schData1
                    },
                    {
                        schData2
                    }
                ]
            }
            const newSchData = {
                data: schData.data.map(item => {
                    // Возьмем первое свойство объекта (например, schData1 или schData2)
                    return Object.values(item)[0];
                })
            };
            axios.post('/platform/curator/store-user-international-scholarship-widget', newSchData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
            axios.post('/platform/curator/users/general-english', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        this.getCources()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
            axios.post('/platform/curator/store-user-document-widget', agree, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
            axios.post('/platform/curator/users/tests', data1, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        this.getTests()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
            axios.post(`/platform/curator/users-individual-plan`, indiv, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
                toast.success('Успешно сохранено, данныые которые вы не заполняли остануться без изменений!')
                this.getPage()
        },
        sendDeadline() {
            const formattedData = this.deadlines.map(item => ({
                ...item,
                date_from: this.formatDate(item.date_from),
                date_to: this.formatDate(item.date_to),
            }));
            const data1 = {
                data: formattedData
            };
            if (formattedData.length > 0) {
                axios.post('/platform/curator/store-user-documents', data1, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                        if (response.status === 201) {
                        } else {
                            toast.success('Документ успешно добавлен!')
                        }
                    })
                    .catch((response) => {
                        toast.error(response.response.data.message);
                    });
            }
            const updatedStatusDate = {
                ...this.statusDate,
                user_admission_id: this.programId
            };
            axios.post('/platform/curator/users/admissions/status', updatedStatusDate, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 201) {
                        toast.success('Статус успешно добавлен')
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.message);
                });
        },
        sendData() {
            if (this.editUser.birthday) {
                this.editUser.birthday = this.formatDate(this.editUser.birthday); // Форматируем дату
            }
            axios.put("/platform/curator/update/user/52", this.editUser, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('goodJob');
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
        },
        sendProcess() {
            const processData = {
                process: this.process,
                stage: this.selectedStage,
                user_id: 52
            }
            axios.post('/platform/curator/users-stage-process', processData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            }).then((response) => {
                if (response.status === 201) {
                    toast.success('Успешно!')
                    this.getPage()
                } else {
                    console.log(response.data);
                }
            })
                .catch((response) => {
                    console.log(response.response.data.errors);
                });
        },
        sendSuper() {
            toast.success("Отправлено");
        },
        deleteGeneral(id) {
            if (id) {
                axios.delete(`/platform/curator/users-general-english?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then(res => {
                        toast.success('Успешно удалено!')
                        this.getCources()
                    })
                    .catch(err => {
                    })
            }
            else {
                this.keyCources.pop();
            }
        },
        deleteRisk(id) {
            axios.delete(`/platform/curator/users-risks/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    toast.success('Успешно удалено!')
                    this.getPage()
                })
                .catch(err => {
                })
        },
        deleteTest(id) {
            if (id) {
                axios.delete(`/platform/curator/users-tests?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then(res => {
                        toast.success('Успешно удалено!')
                        this.getTests()
                    })
                    .catch(err => {
                    })
            }
            else {
                this.keyTests.pop();
            }
        },
        confirmGeneral(item) {
            const data = {
                type: item
            }
            axios.put("/platform/curator/users/status/accept/52", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {
                        toast.success('Успешно подтверждено')
                        this.getPage()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
        },
        confirmResult(item) {
            const data = {
                type: item
            }
            axios.put(`/platform/curator/users/academic-result/accept/4`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then((response) => {
                    if (response.status === 202) {
                        toast.success('Успешно подтверждено')
                        this.getPage()
                    } else {
                        console.log(response.data);
                    }
                })
                .catch((response) => {
                    toast.error(response.response.data.errors);
                });
        },
        async getPage() {
            await axios.get(`/platform/countries`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.countries = res.data.data
            })
            .catch(err => {
            })
            await axios.get(`/platform/curator/users/52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.editUser = res.data.data.user
                    this.results = res.data.data.academicResult
                    this.userData = res.data.data.user
                    const rawData = res.data.data.documents;
                    this.documents = Object.entries(rawData).reduce((acc, [key, value]) => {
                        // Пропускаем поля id и user_id
                        if (key === "id" || key === "user_id") return acc;
                        if (key.endsWith("_status")) {
                            const baseKey = key.replace("_status", "");
                            const baseValue = rawData[baseKey] || null;
                            if (baseValue) { // Добавляем проверку наличия value
                                acc.push({ field: baseKey, value: baseValue, status: value });
                            }
                        } else if (!Object.keys(rawData).includes(`${key}_status`) && value) {
                            acc.push({ field: key, value: value }); // Добавляем только если value есть
                        }
                        return acc;
                    }, []);
                    if (res.data.data.individualPlans.length > 0) {
                        this.individulasFromBack = res.data.data.individualPlans
                    }
                    if (res.data.data.user.process) {
                        this.process = res.data.data.user.process
                    }
                    if (res.data.data.user.stage) {
                        this.selectedStage = res.data.data.user.stage
                    }
                    if (res.data.data.risks) {
                        this.risks = res.data.data.risks
                    }
                    if (res.data.data.documentWidget) {
                        const newData = res.data.data.documentWidget;
                        this.supplyId = newData.supply;
                        this.stepId = newData.study_degree.id;
                        this.contStatusId = newData.status;
                        this.contractDate = newData.send_date;
                        this.countDays = newData.count_of_days;
                        this.contractNumber = newData.contract_number
                    }
                })
                .catch(err => {
                })
            await axios.get(`/platform/study-degrees`, {
            })
                .then(res => {
                    this.steps = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/document-types`)
                .then(res => {
                    this.documentTypes = res.data.data
                })
                .catch(err => {
                })
            await axios.get(`/platform/scholarships`, {
            })
                .then(res => {
                    this.stips = res.data.data
                })
                .catch(err => {
                })
        },
        async getBuys() {
            await axios.get(`/platform/curator/user-purchase-history/52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.userBuys = res.data.data
                })
                .catch(err => {
                })
        },
        async getCources() {
            await axios.get(`/platform/curator/users-general-english?user_id=52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.keyCources = res.data.data
                })
                .catch(err => {
                })
        },
        async getAdmissions() {
            await axios.get(`/platform/curator/users/admissions/52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.admissionPrograms = res.data.data
                })
                .catch(err => {
                })
        },
        async getTests() {
            await axios.get(`/platform/curator/users-tests?user_id=52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.keyTests = res.data.data
                })
                .catch(err => {
                })
        },
        async getFavourites() {
            await axios.get(`/platform/curator/users/favourites/52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.favourites = res.data.universityPrograms
                })
                .catch(err => {
                })
        },
        async getRisks() {
            await axios.get(`/platform/default-risks`)
                .then(res => {
                    this.newRisks = res.data.data
                })
                .catch(err => {
                })
        },
        async getInt() {
            await axios.get(`/platform/curator/user-international-scholarship-widget/52`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
                .then(res => {
                    this.intSch = res.data.data
                })
                .catch(err => {
                })
        }
    },
    async created() {
        this.getBuys()
        this.getPage()
        this.getInt()
        this.getCources()
        this.getAdmissions()
        this.getTests()
        this.getFavourites()
        this.getRisks()
    },
    mounted() {
        window.addEventListener("beforeunload", (event) => {
            if (this.isChanged) {
                event.preventDefault();
                event.returnValue = ""; // Пустая строка активирует стандартное предупреждение
            }
        });
    },
    beforeDestroy() {
        // Удаляем слушатель при уничтожении компонента
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },
};
</script>
<style lang="scss" scoped>
.newcustomInputWhite {
    position: relative;
    display: inline-block;
    width: 32%;
    cursor: pointer;

    label {
        position: absolute;
        top: -8px;
        left: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: #ffffff;
        /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        color: #000000;
    }

    &__in {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        border: 1px solid #e0e0e0;
        /* Цвет рамки */
        border-radius: 8px;
        font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        background-color: inherit;
        color: #000;
        /* Цвет текста */
        outline: none;
        align-items: center;

        input,
        select, .customSelectClass {
            width: 50%;
            border: none;
        }
    }
}

.line1 {
    height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    border-left: 1px solid #0000001A;
}

.activeTab {
    border-bottom: 1px solid red !important;
    color: black !important;
    font-weight: 600 !important;
}

.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}

.line2 {
    border-left: 1px solid #0000001A;
    height: 100%;
}

.bold {
    font-weight: 600;
}

.grey {
    color: #0000008A;
}

select {
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

a {
    color: inherit;
}

.userCard {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    background-color: white;
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__customTop {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
            display: flex;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: center;

            &__right {
                display: flex;
                flex-direction: column;
                gap: 5px;

                &__name {
                    font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                }
            }

            img {
                width: 100px;
                aspect-ratio: 1;
            }
        }

        &__export {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            border: 1px solid #0000001A;
            border-radius: 10px;
            cursor: pointer;

            img {
                width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            }
        }
    }

    &__top {
        display: flex;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        align-items: center;

        &__right {
            display: flex;
            flex-direction: column;
            gap: 5px;

            &__name {
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
            }
        }

        img {
            width: 100px;
            aspect-ratio: 1;
        }
    }

    &__tabs {
        display: flex;
        align-items: flex-end;

        &__in {
            .line2 {
                border-bottom: 1px solid #0000001A;
                width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
            }

            &__name {
                padding-bottom: 5px;
                border-bottom: 1px solid #0000001A;
                color: #0000004D;
            }

            cursor: pointer;
            display: flex;
            align-items: flex-end;
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__inputs,
        &__inputs1 {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            .customInputWhite {
                width: 23.5%;
            }

            &__delete {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                aspect-ratio: 1;
                border: 1px solid #FD2D2A;
                border-radius: 10px;
                transition: all 0.5s ease;

                &:hover {
                    background-color: #FD2D2A;

                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(11%) hue-rotate(189deg) brightness(103%) contrast(105%);
                    }
                }

                img {
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
            }

            &__select {
                width: 15%;

                select {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &__inputs1 {
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            .customInputWhite {
                width: 35%;
            }
        }

        &__risk {
            display: flex;
            gap: 2%;

            input,
            button {
                width: 23.5%;
                height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                border: 1px solid #e0e0e0;
                /* Цвет рамки */
                border-radius: 8px;
                font-style: italic;
                ;
            }

            button {
                border: 2px solid #11415D;
                font-style: normal;
                padding: 0px;
                background-color: inherit;
                color: #11415D;
            }
        }

        &__risks {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: stretch;

            &__in {
                display: flex;
                gap: 10px;
                padding: 10px;
                align-items: center;
                color: white;
                background-color: #FD2D2A;
                cursor: pointer;
                border-radius: 10px;

                img {
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
            }
        }

        &__buttons {
            display: flex;
            gap: 2%;

            button {
                width: 23.5%;
                border: none;
                height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                border-radius: 10px;
            }

            &__save {
                color: white;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #2076a7;
                }
            }

            &__delete {
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                transition: all 0.5s ease;

                &:hover {
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }

    .general {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        .customInputWhite {
            width: 32%;
        }

        &__inputs {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }

        &__buys {
            display: flex;
            gap: 2%;

            &__in,
            &__plus {
                position: relative;
                width: 23.5%;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                justify-content: center;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                border: 1px solid #0000001A;

                &__edit {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    display: flex;
                    gap: 10px;
                    padding: 10px;
                    background-color: #F8F8F8;
                    border-radius: 8px;
                    align-items: center;

                    .line1 {
                        height: 10px;
                    }

                    img {
                        width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px) !important;
                        height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px) !important;
                        cursor: pointer;
                    }
                }

                &__title {
                    color: #0000008A;
                }

                &__name {
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    font-weight: bold;
                }

                &__sub {
                    color: #FD2D2A;
                    font-weight: bold;
                }

                img {
                    width: 100%;
                    aspect-ratio: 2;
                    object-fit: cover;
                }
            }

            &__plus {
                cursor: pointer;
                width: 23.5%;
                aspect-ratio: 1;
                background-color: #F8F8F8;
                box-shadow: 0px 0px 8px 4px #0000000D inset;

                img {
                    width: 30%;
                    aspect-ratio: 1;
                }
            }
        }

        &__progress {
            display: flex;
            flex-direction: column;
            gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                display: flex;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

                button {
                    width: 23.5%;
                    border: none;
                    border-radius: 10px;
                    background-color: #14D448;
                    color: white;
                }
            }
        }
    }

    .document {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__title {
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            font-weight: 700;
        }

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

            &__in {
                width: 23.5%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 4px 3px 1px #0000000D;
                background: #FFFFFF;
                border-radius: 10px;
                position: relative;

                &__delete {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                }

                &__left {
                    width: 15%;

                    img {
                        width: 100%;
                    }
                }

                &__right {
                    width: 75%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    &__name {
                        font-weight: 700;
                        flex: 1;
                    }

                    &__undefined {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        border: 1px solid #0000001A;
                        border-radius: 10px;
                        cursor: pointer;

                        &__left {
                            color: #00D928;
                        }

                        &__right {
                            color: #FF9500;
                        }
                    }

                    &__approved {
                        padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        background-color: #F8F8F8;
                        width: fit-content;
                        border-radius: 10px;
                        color: #0000008A;
                        cursor: pointer;
                    }

                    &__not {
                        padding: 10px min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        background-color: #FF9500;
                        width: fit-content;
                        color: white;
                        border-radius: 10px;
                        cursor: pointer;
                    }
                }
            }

            &__plus {
                width: 23.5%;
                padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px)0px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 8px 4px #0000000D inset;
                border: 1px solid #0000001A;
                border-radius: 10px;
                cursor: pointer;

                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &__info {
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: center;

            img {
                width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            }
        }
    }

    .favourite {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                width: 49%;
            }
        }
    }

    .admission {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);

            &__in {
                width: 49%;
                cursor: pointer;
            }
        }
    }

    .inAdmission {
        display: flex;
        flex-direction: column;
        gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    }
}

.result {
    &__inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);

        &__in {
            width: 32%;

            .newcustomInputWhite {
                width: 100%;
            }

            .customInputWhite {
                width: 100% !important;
            }
        }
    }
}
</style>