<template>
    <EditCurator v-if="openEdit" @closeModal="openEdit = false"/>
    <div class="mainStyle">
        <div class="mainStyle__left">
            <platformTable />
        </div>
        <div class="mainStyle__right">
            <div class="mainStyle__right__top">
                <div class="mainStyle__right__top__left">
                    <p class="mainStyle__right__top__left__title">Главная страница</p>
                </div>
                <div class="mainStyle__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="first">
                <div class="first__left">
                    <div class="info customBackground">
                        <div class="info__top" style="cursor: pointer;">
                            <img :src="userInfo.image" class="info__top__image" @click="openEdit = true"/>
                            <div class="info__top__right" @click="openEdit = true">
                                <p class="info__top__right__name">{{userInfo?.name}}</p>
                                <div class="info__top__right__status">
                                    <img src="@/assets/icons/curatorIcon.svg"/>
                                    <p>{{userInfo?.role?.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="info__status" @dblclick="disactive(userInfo.id)" v-if="userInfo.status == 'Активный'">
                            Статус: <span>Активен</span>
                        </div>
                        <div class="info__status" v-else style="background-color: red;" @dblclick="active(userInfo.id)">
                            Статус: <span>Неактивен</span>
                        </div>
                    </div>
                    <div class="apps customBackground">
                        <div class="apps__top customTop">
                            <div class="apps__top__left customTop__left">
                                <img src="@/assets/icons/app.svg"/>
                                <p>Заявки на обсуждение</p>
                            </div>
                        </div>
                        <div class="apps__bot">
                            <swiper 
                            class="apps__bot__swiper"
                                :breakpoints="{
                                    0: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3
                                    }
                                }"
                                :pagination="{
                                     el: '.swiper-pag',
                                }"
                                :spaceBetween="40"
                                :speed="500"
                                :lazy="true" 
                            > 
                                <swiper-slide v-for="(item, index) in meetings" class="apps__bot__swiper__slide"
                                    :key="item"> 
                                    <img :src="item?.user?.image_url"/>
                                    <p class="text">ID:{{item?.user?.id}}</p>
                                    <p class="name">{{item?.user?.name}}</p>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiper-pag"></div>
                        </div>
                    </div>
                </div>
                <div class="first__center">
                    <div class="achieve customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/achieveIcon.svg"/>
                                <p>Результаты</p>
                            </div>
                            <button @click="saveData">Сохранить</button>
                        </div>
                        <div class="achieve__block">
                            <div class="achieve__block__in">
                                <div class="achieve__block__in__top">
                                    <div class="achieve__block__in__top__circle text">
                                        <input type="text" :placeholder="results[0]?.value" v-model="result_1" />
                                    </div>
                                    <p>Офферы топ-10</p>
                                </div>
                            </div>
                            <div class="achieve__block__in">
                                <div class="achieve__block__in__top">
                                    <div class="achieve__block__in__top__circle text">
                                        <input type="text" :placeholder="results[1]?.value" v-model="result_2"/>
                                    </div>
                                    <p>Офферы топ-50</p>
                                </div>
                            </div>
                            <div class="achieve__block__in">
                                <div class="achieve__block__in__top">
                                    <div class="achieve__block__in__top__circle text">
                                        <input type="text" :placeholder="results[2]?.value" v-model="result_3"/>
                                    </div>
                                    <p>Офферы топ-100</p>
                                </div>
                            </div>
                            <div class="achieve__block__in">
                                <div class="achieve__block__in__top">
                                    <div class="achieve__block__in__top__circle text">
                                        <input type="text" :placeholder="results[3]?.value" v-model="result_4"/>
                                    </div>
                                    <p>Офферы топ-250</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="first__last">
                    <div class="count customBackground">
                        <div class="customTop">    
                            <div class="customTop__left">
                                <img src="@/assets/icons/achieveIcon.svg"/>
                                <p>Клиенты</p>
                            </div>
                        </div>
                        <div class="count__bot">
                            <div class="count__bot__in">
                                <p class="name">{{statistic.totalUserCount}}</p>
                                <p class="text">Общее</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.activeUserCount}}</p>
                                <p class="text">Активные</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.deactivateUserCount}}</p>
                                <p class="text">Неактивные</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.problemUserCount}}</p>
                                <p class="text">Проблемные</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.riskCount}}</p>
                                <p class="text">С рисками</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.thisYearAuthCount}}</p>
                                <p class="text">2025 г.</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.nextYearAuthCount}}</p>
                                <p class="text">2026 г.</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.secondTryCount}}</p>
                                <p class="text">Переподача</p>
                            </div>
                            <div class="count__bot__in">
                                <p class="name">{{statistic.expiredContractsCount}}</p>
                                <p class="text">Истек договор</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="second">
                <div class="second__left">
                    <div class="clients customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/clients.svg"/>
                                <p>Клиенты дня</p>
                            </div>
                        </div>
                        <div class="clients__bot">
                            <swiper 
                            class="clients__bot__swiper"
                                :breakpoints="{
                                    0: {
                                        slidesPerView: 6,
                                        slidesPerGroup: 6
                                    }
                                }"
                                :pagination="{
                                     el: '.swiper-pag1',
                                }"
                                :spaceBetween="40"
                                :speed="500"
                                :lazy="true" 
                            > 
                                <swiper-slide v-for="(item, index) in clientsofday" class="clients__bot__swiper__slide"
                                    :key="item"> 
                                    <img :src="item?.user?.image"/>
                                    <p class="text">ID:{{item.user.id}}</p>
                                    <p class="name">{{item.user.name}}</p>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiper-pag1"></div>
                        </div>
                    </div>
                </div>  
                <div class="second__last">
                    <div class="access customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/access.svg"/>
                                <p>Доступ к ресурсам</p>
                            </div>
                        </div>
                        <div class="access__bot">
                            <div class="access__bot__in" v-for="item in recources" :key="item">
                                <a :href="item.link"><img :src="$store.state.cdn + item.icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import { mapActions, mapGetters } from "vuex";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import EditCurator from "@/components/newPlatform/editCurator.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        EditCurator,
    },
    data() {
        return {
            openEdit: false,
            meetings: false,
            statistic: false,
            clientsofday: false,
            results: false,
            recources: false,
            result_1: null,
            result_2: null,
            result_3: null,
            result_4: null,
        };
    },
    methods: {
        ...mapActions(["requestUser"]),
        disactive(id){
            axios.put(`/platform/curator/deactivate-user/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 200) {
                this.userInfo.status == 'Неактивный'
                window.location.reload();
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        }, 
        active(id){
            axios.put(`/platform/curator/activate-user/${id}`, '', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 200) {
                this.userInfo.status == 'Активный'
                window.location.reload();
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        saveData() {
            // Пройдемся по всем результатам и отправим их, если они изменились
            this.results.forEach((result, index) => {
                // Проверяем, изменился ли текущий результат
                if (this[`result_${index + 1}`] !== result.value) {
                    const data = {
                        id: index + 1, // Привязываем ID к индексу
                        title: {
                            ru: "ru",
                            kz: "kz",
                            en: "en"
                        },
                        value: this[`result_${index + 1}`] // Используем динамическое свойство
                    };

                    // Отправляем запрос
                    axios.post('/platform/curator/store-results', data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        }
                    })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success(`Результат ${index + 1} успешно обновлен!`);
                        } else {
                            console.log(response.data);
                        }
                    })
                    .catch((error) => {
                        console.error(`Ошибка обновления результата ${index + 1}:`, error.response?.data?.errors || error.message);
                    });
                }
            });
        },
        realMethod(item){
            const data = {
                id: item.id
            }
            axios.post('/platform/curator/store-client-of-day', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then((response) => {
            if (response.status === 201) {
                toast.success('Успешно добавлено!')
            } else {
                console.log(response.data);
            }
            })
            .catch((response) => { 
                console.log(response.response.data.errors);
            });
        },
        async getPage() {    
            await axios.get(`/platform/curator/meetings`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.meetings = res.data.data
            })
            .catch(err => {
            })      
            await axios.get(`/platform/curator/user-statistics`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.statistic = res.data.data
            })
            .catch(err => {
            })  
            await axios.get(`/platform/curator/client-of-day`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.clientsofday = res.data.data
            })
            .catch(err => {
            })   
            await axios.get(`/platform/curator/results`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.results = res.data.data
            })
            .catch(err => {
            })  
            await axios.get(`/platform/curator/resource-links`, {
            headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.recources = res.data.data
            })
            .catch(err => {
            }) 
        },
    },
    computed:{
        ...mapGetters(["userInfo"])
    },
    async created() {
        this.requestUser();
        this.getPage();
    },
};
</script>
<style lang="scss" scoped>
.text{
    color: #0000008A;
    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
}
.name{
    color: black;
    font-weight: 700;
    text-align: center;
    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
select{
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
}
.first{
    display: flex;
    align-items: stretch;
    gap: 3.5%;
    &__left,&__center,&__last{
        width: 31%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .achieve{
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block{
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            display: flex;
            flex-wrap: wrap;
            gap: 30%;
            row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            &__in{
                width: 35%;
                &__top{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    &__circle{
                        width: 100%;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        border: 8px solid #FD2D2A;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        input{
                            width: 90%;
                            text-align: center;
                            border: none;
                            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                        }
                    }
                }
            }
        }
    }
    .info{
        width: 100%;
        padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        height: fit-content;
        &__top{
            display: flex;
            gap: 10px;
            align-items: center;
            &__image{
                width: 25%;
                aspect-ratio: 1;
                border-radius: 50%;
                object-fit: cover;
            }
            &__right{
                display: flex;
                flex-direction: column;
                gap: 10px;
                img{
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                }
                &__name{
                    color: #11415D;
                    font-weight: 700;
                    font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                }
                &__status{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    color: #FD2D2A;
                    font-weight: 600;
                }
            }
        }
        &__status{
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 10px 0;
            background-color: #14D448;
            border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            color: white;
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            cursor: pointer;
            span{
                font-weight: 600;
        
            }
        }
    }
    .apps{
        height: fit-content;
        &__bot{
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            width: 100%;
            &__swiper{
                width: 100%;
                &__slide{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    gap: 5px;
                    img{
                        width: 100%;
                        aspect-ratio: 1;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    .count{
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__bot{
            display: flex;
            flex-wrap: wrap;
            gap: 5%;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)80px;
            &__in{
                width: 30%;
                aspect-ratio: 1;
                padding: 10px;
                text-align: center;
                box-shadow: 0px 0px 4px 4px #0000000D inset;
                background-color: #F8F8F8;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            }
        }
    }
}
.second{
    display: flex;
    justify-content: space-between;
    &__left{
        width: 65.5%;
        .clients{
            &__bot{
                padding: 10px;
                width: 100%;
                &__swiper{
                    width: 100%;
                    &__slide{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;
                        gap: 5px;
                        img{
                            width: 100%;
                            aspect-ratio: 1;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
    &__last{
        width: 31%;
        .access{
            &__bot{
                padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                flex-wrap: wrap;
                gap: 4%;
                row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                &__in{
                    width: 22%;
                    cursor: pointer;
                    img{
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
</style>