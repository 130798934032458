<template>
    <addPodcast :type="'option'" @closeModal="openModal = false, isEdit = false" v-if="openModal" :isEdit="isEdit"/>
    <div class="mainStyle3">
        <div class="mainStyle3__left">
            <platformTable />
        </div>
        <div class="mainStyle3__right">
            <div class="mainStyle3__right__top">
                <div class="mainStyle3__right__top__left">
                    <p class="mainStyle3__right__top__left__title">Премиум опции</p>
                </div>
                <div class="mainStyle3__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="customTabTitle">
                <p class="customTabTitle__left">Категория “Купите новые услуги”</p>
            </div>
            <div class="block">
                <div class="block__in"  v-for="item in 5">
                    <GrantCard @openModal="openModal = true, isEdit = true"/>
                </div>
                <div class="plus" v-if="$store.state.role == 'supervisor'" @click="openModal = true">
                    <img src="@/assets/icons/plus.svg"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import AboutProgram from '@/components/newPlatform/aboutProgram.vue';
import customSelect from '@/components/customSelect.vue';
import GrantCard from '@/components/grantCard.vue';
import addPodcast from '@/components/supervisor/addPodcast.vue';
export default {
    components: {
        Swiper,
        GrantCard,
        SwiperSlide,
        platformTable,
        userInfo,
        AboutProgram,
        customSelect,addPodcast
    },
    data() {
        return {
            openAdd: false,
            openModal: false
        }
    }
}
</script>
<style lang="scss" scoped>
.block{
    display: flex;
    flex-wrap: wrap;
    gap: 2.5%;
    row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    align-items: stretch;
    &__in{
        width: 18%;
    }
}.plus{
    border: 1px solid #0000001A;
    width: 18%;
    display: flex;
    aspect-ratio: 0.763;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 8px 4px #0000000D inset;
    border-radius: 10px;
    cursor: pointer;
}
</style>