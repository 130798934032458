<template>
    <success v-if="openSuccess" @closeModal="handleCloseSuccess" />
    <div class="program" :class="{customProgram: $store.state.role == 'curator'}">
        <div class="program__left">
            <platformTable />
        </div>
        <div class="program__right">
            <div class="program__right__top">
                <div class="program__right__top__title" v-if="$store.state.role == 'user'">
                    Поиск программ > <span> {{newData?.programName}}</span>
                </div>
                <div class="program__right__top__title" v-else>
                    Пользователи > Максим М. > <span>Избранный ВУЗ</span>
                </div>
                <div class="program__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="forCurator" :class="{customCurator: $store.state.role == 'curator'}">
                <div class="videoBlock" >
                <div class="videoBlock__swiperBlock" v-if="$store.state.role == 'user'">
                    <div class="videoBlock__swiperBlock__top">
                        <img src="@/assets/icons/arrowRight.svg" class="rightArrow"/>
                        <img src="@/assets/icons/arrowRight.svg" style="rotate: 180deg;" class="leftArrow"/>
                        <Swiper 
                        class="videoBlock__swiperBlock__top__swiper"
                        :navigation="{nextEl: '.rightArrow', prevEl: '.leftArrow'}"
                        :speed="400"
                        :thumbs="{ swiper: thumbsSwiper }"
                        :lazy="true" :loop="true"
                        :breakpoints="{
                            0: {
                            slidesPerView: 1,
                            spaceBetween: 0
                            }
                        }">
                        <SwiperSlide v-for="(item) in newData.images" :key="item" class="videoBlock__swiperBlock__top__swiper__slide">
                            <img :src="item.image" />
                        </SwiperSlide>
                        </Swiper>
                    </div>
                    <div class="videoBlock__swiperBlock__bot">
                        <Swiper
                        class="videoBlock__swiperBlock__bot__swiper"
                        @swiper="setThumbsSwiper"
                        :centerInsufficientSlides="true"
                        :lazy="true" :loop="false"
                        :speed="1000"
                        :breakpoints="{
                            0: {
                                slidesPerView: 4,
                                spaceBetween: 20
                            }
                        }">
                        <SwiperSlide v-for="(item) in newData.images" :key="item" class="videoBlock__swiperBlock__bot__swiper__slide" :style="{ backgroundImage: `url(${item.image})` }">
                            
                        </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div class="videoBlock__info">
                    <div class="videoBlock__info__left">
                        <div class="videoBlock__info__left__rate">TOP-{{newData?.top}}</div>
                        <div class="videoBlock__info__left__info">
                            <p class="videoBlock__info__left__info__text">{{newData?.programName}}</p>
                            <p class="customTitle">{{newData?.university?.title}}</p>
                        </div>
                    </div>
                    <div class="videoBlock__info__right">
                        <div class="videoBlock__info__right__heart">
                            <img src="@/assets/icons/heart.svg" />
                        </div>
                        <a :href="newData?.link" class="videoBlock__info__right__world">
                            <img src="@/assets/icons/tab_5.svg" />
                            <div class="redLine"></div>
                            <p>Сайт программы</p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="program__right__desc">
                <p class="customTitle">Описание</p>
                <p class="program__right__desc__text" v-html="newData?.description">
                </p>
            </div>
            <div class="program__right__info">
                <p class="customTitle">Подробная информация</p>
                <div class="program__right__info__block">
                    <div class="program__right__info__block__in" v-for="item in programInfo" :key="item">
                        <img :src="require('@/assets/icons/' + item.image + '.svg')"/>
                        <p class="program__right__info__block__in__label">{{ item.label }}</p>
                        <div class="line"></div>
                        <p class="program__right__info__block__in__value">{{ item.value }}</p>
                    </div>
                </div>
            </div>
            {{ img_1 }}
            <div class="program__right__areas">
                <div class="program__right__areas__left">
                    <p class="program__right__areas__left__title">Академические требования</p>
                    <div class="line"></div>
                    <div v-html="newData.workExperience"></div>
                </div>
                <div class="program__right__areas__left">
                    <p class="program__right__areas__left__title">Перечень документов</p>
                    <div class="line"></div>
                    <div v-html="newData.docs"></div>
                </div>
            </div>
            <div class="program__right__map">
                <p class="customTitle">На карте мира</p>
                <div class="program__right__map__image" :class="{customMap: openMap == true}" @click="openMap = !openMap">
                    <GoogleMap
                    api-key="AIzaSyDaC4rs2OfcZhf3Pnn9uegUI6h8dUnu2fY"
                    style="width: 100%; height: 100%"
                    :center="center"
                    :zoom="15"
                    >
                        <Marker :options="{ position: center }" />
                    </GoogleMap>
                </div>
            </div>
            <div class="program__right__old" v-if="$store.state.role == 'user'">
                <p class="customTitle">Нашли устаревшую информацию?</p>
                <div class="program__right__old__block">
                    <textarea placeholder="...."></textarea>
                    <p>Описание правил для отправления формы о устаревшей информации университета. Massachusetts Institute of Technology, founded in 1861</p>
                </div>
            </div>
            <div class="program__right__buttons" :class="{customButtons: $store.state.role == 'curator'}">
                <button class="program__right__buttons__send" @click="openSuccess = true" v-if="$store.state.role == 'user'">
                    <img src="@/assets/icons/send.svg"/>
                    <p>Отправить</p>
                </button>
                <button class="program__right__buttons__send" v-else>
                    <p>Добавить в поступление</p>
                </button>
                <button class="program__right__buttons__file" v-if="$store.state.role == 'user'">
                    <img src="@/assets/icons/plus.svg" />
                    <p>Добавить файл</p>
                </button>
                <button class="program__right__buttons__file" v-else>
                    <p>Редактировать</p>
                </button>
            </div>
            <!-- <GMapMap
                :center="center"
                :zoom="7"
                map-type-id="terrain"
                style="width: 500px; height: 300px"
            >
                <GMapCluster>
                <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                />
                </GMapCluster>
            </GMapMap> -->
            <div class="line" v-if="$store.state.role == 'user'"></div>
            <div class="program__right__more" v-if="$store.state.role == 'user'">
                <p class="customTitle">Похожие программы</p>
                <div class="program__right__more__block">
                    <div class="program__right__more__block__in" v-for="item in newData?.similarPrograms" :key="item">
                        <NewCardComponent :item="item"/>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import userInfo from '@/components/userInfo.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import "swiper/css/thumbs";
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination, Thumbs } from 'swiper'; 
import "swiper/swiper.min.css"; 
import success from '@/components/newPlatform/success.vue';
import NewCardComponent from '@/components/newCardComponent.vue';
import img_1 from '@/assets/icons/test.png'
SwiperCore.use([Navigation,Pagination, A11y, Autoplay, Thumbs])
import axios from "axios";
import { GoogleMap, Marker } from 'vue3-google-map'
export default {
    components: {
        platformTable, userInfo, Swiper, SwiperSlide, NewCardComponent, success, GoogleMap, Marker
    },
    data() {
        return {
            thumbsSwiper: '',
            center: {lat: 42.34964619184934, lng: -71.25924450000001},  
            openSuccess: false,
            openMap: false,
            newData: [],
            programInfo: [
            { 
                label: "Уровень", 
                value: `TOP-30` ,
                image: "img_1"
            },
                { label: "Код программы", value: "876875", image: "img_2" },
                { label: "Длительность", value: "1 год", image: "img_3" },
                { label: "Стоимость", value: "+150k/yr", image: "img_4" },
                { label: "Страна", value: "США" , image: "img_5"},
                { label: "Город", value: "Кембридж" , image: "img_6"},
                { label: "GPA", value: "3.8" , image: "img_7"},
                { label: "IELTS / TOEFL", value: ">5" , image: "img_8"},
                { label: "GRE/GMAT", value: "мин. 5" , image: "img_9"},
                { label: "Deadline 1", value: "10.12.2024" , image: "img_10"},
                { label: "Deadline 2", value: "12.12.2024" , image: "img_10"},
                { label: "Application fee", value: "50 USD" , image: "img_4"},
            ]
        }
    },
    methods: {
        setThumbsSwiper(item){
            this.thumbsSwiper = item
        },
        async getPage() {
            await axios.get(`/V1/universityPrograms/14`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
                this.newData = res.data.program
                this.updateProgramInfo();
            })
            .catch(err => {
            })
        },
        updateProgramInfo() {
            this.programInfo = [
                { label: "Уровень", value: `TOP-${this.newData.top}`, image: "img_1" },
                { label: "Код программы", value: `${this.newData.code}`, image: "img_2" },
                { label: "Длительность", value: `${this.newData.duration}`, image: "img_3" },
                { label: "Стоимость", value: `${this.newData.price}`, image: "img_4" },
                { label: "Страна", value: `${this.newData.country}`, image: "img_5" },
                { label: "Город", value: "Кембридж", image: "img_6" },
                { label: "GPA", value: `${this.newData.gpa}`, image: "img_7" },
                { label: "IELTS / TOEFL", value: ">5", image: "img_8" },
                { label: "GRE/GMAT", value: "мин. 5" , image: "img_9"},
                { label: "Deadline 1", value: "10.12.2024" , image: "img_10"},
                { label: "Deadline 2", value: "12.12.2024" , image: "img_10"},
                { label: "Application fee", value: "50 USD" , image: "img_4"},
            ];
        },
        handleCloseSuccess() {
            if (this.openSuccess) {
                this.openSuccess = false;
            }
        },
    },
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.customButtons{
    justify-content: center;
}
.customTitle {
    font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
    font-weight: 600 !important;
    color: #000;
}
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
.redLine {
    border: 1px solid #FD2D2A;
    height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
}
.leftArrow, .rightArrow {
    position: absolute;
    width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    top: 50%;
    transform: translate(-50%, 0%); /* Центрирование относительно родительского элемента */
    z-index: 2;
    cursor: pointer;
}
.leftArrow{
    left: 0px;
}
.rightArrow{
    right: 0px;
}
.forCurator{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.customProgram{
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%) !important;
}
.customCurator{
    background-color: white;
    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    border-radius: 10px;
}
.videoBlock {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__swiperBlock{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__top{
            width: 100%;
            position: relative;
            &__swiper{
                width: 100%;
                border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                &__slide{
                    width: 100%;
                    aspect-ratio: 2.5;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        &__bot{
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            &__swiper{
                width: 100%;
                    &__slide{
                        width: 100%;
                        aspect-ratio: 2.5;
                        border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                        position: relative;
                        background-image: url('/src/assets/icons/test.png');
                        background-position: center;
                        background-size: cover;
                    }
                }
                .swiper-slide-thumb-active{
                    &::before {
                    content: "";
                    background-image: url("@/assets/Eye.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    background-color: #0000008A;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                }
            }
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__left {
            display: flex;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: stretch;
            &__rate {
                padding: 10px min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FD2D2A;
                border-radius: 10px;
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                font-weight: 600;
                color: white;
            }
            &__info{
                display: flex;
                flex-direction: column;
                &__text{
                    color: #0000008A;
                }
            }
        }

        &__right {
            display: flex;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: center;

            img {
                width: 27px;
                filter: brightness(0) saturate(100%) invert(25%) sepia(51%) saturate(4554%) hue-rotate(349deg) brightness(108%) contrast(109%);
            }

            &__heart,
            &__world {
                padding: 10px min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                border: 1px solid #FD2D2A;
                border-radius: 10px;
                cursor: pointer;
            }

            &__world {
                display: flex;
                gap: 5px;
                align-items: center;
                font-weight: 700;
                color: #FD2D2A;
            }
        }
    }
}
.program {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__areas{
            display: flex;
            justify-content: space-between;
            &__left{
                width: 48%;
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            }
        }
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                color: #0000008A;
                span {
                    font-weight: 600;
                    color: black;
                }
            }

            &__right {
                width: 25%;
            }
        }
        &__desc {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

            &__text {
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                color:#0000008A;
                span {
                    font-weight: 700;
                    color: black;
                }
            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            &__block {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 5%;
                row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                &__in {
                    width: 10%;
                    display: flex;
                    flex-direction: column;
                    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                    img{
                        width: 30%;
                        aspect-ratio: 1;
                        height: auto;
                    }
                    &:nth-child(1) &__value {
                        color: #FD2D2A;
                    }
                    &__value{
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        font-weight: 700;
                        color: #11415D;
                    }
                }
            }
        }
        &__map {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            .customMap{
                aspect-ratio: 2.5;
            }
            &__image{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-end;
                justify-content: center;
                cursor: pointer;
                width: 100%;
                aspect-ratio: 5;
                object-fit: cover;
                background-position: center;
                background-size: cover;
                height: 100%;
                border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                border: 1px solid #0000001A;
                box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.05);
                color: white;
                position: relative;
                p{
                    z-index: 1;
                }
                img{
                    width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    z-index: 1;
                }
            }
        }
        &__old {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            &__block{
            display: flex;
            justify-content: space-between;
                textarea {
                    width: 48%;
                    cursor: pointer !important;
                    height: 200px;
                    padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    border: 1px solid #e0e0e0;
                    /* Цвет рамки */
                    border-radius: 8px;
                    font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    background-color: inherit;
                    font-weight: bold;
                    color: #000;
                    /* Цвет текста */
                    outline: none;
                    resize: none;
                }
                p{
                    width: 48%;
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    line-height: 42px;
                    color: #0000008A;
                }
            }
        }
        &__buttons {
            display: flex;
            gap: 2%;
            button {
                width: 23.5%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
                border-radius: 10px;
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
            }
            img {
                width: 22.5px;
            }
            &__send {
                background-color: #11415D;
                color: white;
                border: none;
            }
            &__file {
                border: 1px solid #0000001A;
                background-color: inherit;
            }
        }
        &__more{
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            &__block{
                display: flex;
                flex-wrap: wrap;
                gap: 4%;
                row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                &__in{
                    width: 48%;
                }
            }
        }
    }
}
</style>