<template>
    <EditCurator v-if="openEdit" @closeModal="openEdit = false"/>
    <div class="mainStyle3">
        <div class="mainStyle3__left">
            <platformTable />
        </div>
        <div class="mainStyle3__right">
            <div class="mainStyle3__right__top">
                <div class="mainStyle3__right__top__left">
                    <p class="mainStyle3__right__top__left__title">Кураторы</p>
                    <p style="color: #0000008A;">6 пользователей</p>
                </div>
                <div class="mainStyle3__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="block">
                <div class="customTop">
                    <div class="customTop__left">
                        <img src="@/assets/icons/users.svg"/>
                        <p>Список кураторов</p>
                    </div>
                </div>
                <div class="customTable">
                    <div class="customTable__left">
                        <div class="customTable__left__top">
                            <input type="checkbox" />
                            <p>Имя пользователя</p>
                        </div>
                        <div class="customTable__left__bot" v-for="item in 6">
                            <input type="checkbox" />
                            <div class="customTable__left__bot__person">
                                <img src="@/assets/icons/person.svg" />
                                <div class="customTable__left__bot__person__info">
                                    <p class="customTable__left__bot__person__info__id">ID: 896879</p>
                                    <p class="customTable__left__bot__person__info__name" @click="$router.push('/curator/users')">Анна Дублина</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 1">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__et1">Этап1</p>
                            <p class="customTable__right__top__et1">Этап2</p>
                            <p class="customTable__right__top__et1">Этап3</p>
                            <p class="customTable__right__top__et1">Этап4</p>
                            <p class="customTable__right__top__ielts">IELTS</p>
                        </div>
                        <div class="customTable__right__bot" v-for="item in 6">
                            <div class="customTable__right__bot__et1">Значение</div>
                            <div class="customTable__right__bot__et1">Значение</div>
                            <div class="customTable__right__bot__et1">Значение</div>
                            <div class="customTable__right__bot__et1">Значение</div>
                            <div class="customTable__right__bot__ielts">Значение</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import EditCurator from "@/components/newPlatform/editCurator.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        EditCurator
    },
    data() {
        return {
            openEdit: false,
            opentable: 1
        };
    },
    methods: {
        saveData(){
            toast.success('Сохранено')
        }
    }
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.block{
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}
</style>