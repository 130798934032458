<template>
    <div class="mainStyle3">
        <div class="mainStyle3__left">
            <platformTable />
        </div>
        <div class="mainStyle3__right">
            <div class="mainStyle3__right__top">
                <div class="mainStyle3__right__top__left">
                    <p class="mainStyle3__right__top__left__title"> Главная страница</p>
                </div>
                <div class="mainStyle3__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="blocks">
                <div class="blocks__top">
                    <div class="info">
                        <div class="info__top">
                            <div class="info__top__left">
                                <img src="@/assets/icons/curator.svg"/>
                            </div>
                            <div class="info__top__right">
                                <p class="info__top__right__name">Джеймс Д.</p>
                                <div class="info__top__right__spec">
                                    <img src="@/assets/icons/curatorIcon.svg"/>
                                    <p>Супервайзер</p>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="info__status">
                            Статус:  <span>Активен</span>
                        </div>
                    </div>
                    <div class="results customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/app.svg"/>
                                <p>Результаты</p>
                            </div>
                            <div class="customTop__select">
                                <CustomSelect :mini-select="true"/>
                            </div>
                        </div>
                            <swiper 
                                class="results__swiper"
                                :slidesPerView="1" 
                                :speed="1000"
                                :pagination="{ 
                                            clickable: true, 
                                            el: '.swiperNav1'
                                }"
                                :lazy="true" 
                                :loop="true"
                            > 
                                <swiper-slide v-for="(item) in 4" :key="item" class="results__swiper__slide"> 
                                    <div class="results__swiper__slide__left">
                                        <img src="@/assets/icons/resul.svg"/>
                                    </div>
                                    <div class="results__swiper__slide__right">
                                        <p class="results__swiper__slide__right__top">ТОП-30</p>
                                        <p class="results__swiper__slide__right__count">34</p>
                                    </div>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiperNav1"></div>
                    </div>
                    <div class="new customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/app.svg"/>
                                <p>Новых покупок</p>
                            </div>
                            <div class="customTop__select">
                                <CustomSelect :mini-select="true"/>
                            </div>
                        </div>
                        <swiper 
                                class="results__swiper"
                                :slidesPerView="1" 
                                :speed="1000"
                                :pagination="{ 
                                            clickable: true, 
                                            el: '.swiperNav2'
                                }"
                                :lazy="true" 
                                :loop="true"
                            > 
                                <swiper-slide v-for="(item) in 4" :key="item" class="results__swiper__slide"> 
                                    <div class="results__swiper__slide__left">
                                        <img src="@/assets/icons/basketBlack.svg"/>
                                    </div>
                                    <div class="results__swiper__slide__right">
                                        <p class="results__swiper__slide__right__top">Новых гайдов куплено</p>
                                        <p class="results__swiper__slide__right__count">156</p>
                                    </div>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiperNav2"></div>
                    </div>
                </div> 
                <div class="blocks__bot">
                    <div class="clients customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/achieveIcon.svg"/>
                                <p>Клиенты</p>
                            </div>
                            <div class="customTop__select">
                                <CustomSelect :mini-select="true"/> 
                            </div>  
                        </div>
                        <div class="clients__block">
                            <div class="clients__block__in" v-for="item in 9">
                                <p class="name">32</p>
                                <p class="text">Кол.</p>
                            </div>
                        </div>
                    </div>
                    <div class="blocks__bot__right">
                        <div class="blocks__bot__right__top">
                            <div class="request customBackground">
                            <div class="customTop">
                                <div class="customTop__left">
                                    <img src="@/assets/icons/app.svg"/>
                                    <p>Заявки на обсуждение</p>
                                </div>
                            </div>
                            <div class="request__bot">
                                <swiper 
                            class="request__bot__swiper"
                                :breakpoints="{
                                    0: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3
                                    }
                                }"
                                :pagination="{
                                     el: '.swiper-pag',
                                }"
                                :spaceBetween="40"
                                :speed="500"
                                :lazy="true" 
                            > 
                                <swiper-slide v-for="(item, index) in 12" class="request__bot__swiper__slide"
                                    :key="item"> 
                                    <img src="@/assets/icons/appUser.svg"/>
                                    <p class="text">ID:897688</p>
                                    <p class="name">Иван И.</p>
                                </swiper-slide> 
                            </swiper>
                            </div>
                            <div class="swiper-pag"></div>  
                            </div>
                            <div class="progress1 customBackground">
                            <div class="customTop">
                                <div class="customTop__left">
                                    <img src="@/assets/icons/app.svg"/>
                                    <p>Прогресс бар</p>
                                </div>
                                <div class="customTop__select">
                                    <CustomSelect :mini-select="true"/>
                                </div>
                            </div>
                            <div class="progress1__block">
                                <div class="progress1__block__in" v-for="prog in 4" :key="prog">
                                    <p>Этап 1</p>
                                    <div class="progress1__block__in__color"></div>
                                    <p class="progress1__block__in__percent">54</p>
                                </div>
                            </div>
                            </div>
                        </div>  
                        <div class="blocks__bot__right__bot">
                            <div class="statistic customBackground">
                            <div class="customTop">
                                <div class="customTop__left">
                                    <img src="@/assets/icons/app.svg"/>
                                    <p>Статистика</p>
                                </div>
                                <div class="customTop__select">
                                    <CustomSelect :mini-select="true"/>
                                </div>
                            </div>
                            <swiper 
                                class="results__swiper"
                                :slidesPerView="1" 
                                :speed="1000"
                                :pagination="{ 
                                            clickable: true, 
                                            el: '.swiperNav3'
                                }"
                                :lazy="true" 
                                :loop="true"
                            > 
                                <swiper-slide v-for="(item) in 4" :key="item" class="results__swiper__slide"> 
                                    <div class="results__swiper__slide__left">
                                        <img src="@/assets/icons/resul.svg"/>
                                    </div>
                                    <div class="results__swiper__slide__right">
                                        <p class="results__swiper__slide__right__top">ТОП-30</p>
                                        <p class="results__swiper__slide__right__count">34</p>
                                    </div>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiperNav3"></div>
                            </div>
                            <div class="access customBackground">
                                <div class="customTop">
                                    <div class="customTop__left">
                                        <img src="@/assets/icons/access.svg"/>
                                        <p>Доступ к ресурсам</p>
                                    </div>
                                </div>
                                <div class="access__bot">
                                    <div class="access__bot__in" v-for="item in 8" :key="item">
                                        <img src="@/assets/icons/tg.svg" /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="blocks__bot__center">
                        <div class="request customBackground">
                            <div class="customTop">
                                <div class="customTop__left">
                                    <img src="@/assets/icons/app.svg"/>
                                    <p>Заявки на обсуждение</p>
                                </div>
                            </div>
                            <div class="request__bot">
                                <swiper 
                            class="request__bot__swiper"
                                :breakpoints="{
                                    0: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 3
                                    }
                                }"
                                :pagination="{
                                     el: '.swiper-pag',
                                }"
                                :spaceBetween="40"
                                :speed="500"
                                :lazy="true" 
                            > 
                                <swiper-slide v-for="(item, index) in 12" class="request__bot__swiper__slide"
                                    :key="item"> 
                                    <img src="@/assets/icons/appUser.svg"/>
                                    <p class="text">ID:897688</p>
                                    <p class="name">Иван И.</p>
                                </swiper-slide> 
                            </swiper>
                            </div>
                            <div class="swiper-pag"></div>  
                        </div>
                        <div class="statistic customBackground">
                            <div class="customTop">
                                <div class="customTop__left">
                                    <img src="@/assets/icons/app.svg"/>
                                    <p>Статистика</p>
                                </div>
                                <div class="customTop__select">
                                    <CustomSelect :mini-select="true"/>
                                </div>
                            </div>
                            <swiper 
                                class="results__swiper"
                                :slidesPerView="1" 
                                :speed="1000"
                                :pagination="{ 
                                            clickable: true, 
                                            el: '.swiperNav3'
                                }"
                                :lazy="true" 
                                :loop="true"
                            > 
                                <swiper-slide v-for="(item) in 4" :key="item" class="results__swiper__slide"> 
                                    <div class="results__swiper__slide__left">
                                        <img src="@/assets/icons/resul.svg"/>
                                    </div>
                                    <div class="results__swiper__slide__right">
                                        <p class="results__swiper__slide__right__top">ТОП-30</p>
                                        <p class="results__swiper__slide__right__count">34</p>
                                    </div>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiperNav3"></div>
                        </div>
                    </div>
                    <div class="blocks__bot__right">
                        <div class="progress1 customBackground">
                            <div class="customTop">
                                <div class="customTop__left">
                                    <img src="@/assets/icons/app.svg"/>
                                    <p>Прогресс бар</p>
                                </div>
                                <div class="customTop__select">
                                    <CustomSelect :mini-select="true"/>
                                </div>
                            </div>
                            <div class="progress1__block">
                                <div class="progress1__block__in" v-for="prog in 4" :key="prog">
                                    <p>Этап 1</p>
                                    <div class="progress1__block__in__color"></div>
                                    <p class="progress1__block__in__percent">54</p>
                                </div>
                            </div>
                        </div>
                        <div class="access customBackground">
                        <div class="customTop">
                            <div class="customTop__left">
                                <img src="@/assets/icons/access.svg"/>
                                <p>Доступ к ресурсам</p>
                            </div>
                        </div>
                        <div class="access__bot">
                            <div class="access__bot__in" v-for="item in 8" :key="item">
                                <img src="@/assets/icons/tg.svg" /> 
                            </div>
                        </div>
                    </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import CustomSelect from "@/components/customSelect.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        CustomSelect
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.text{
    color: #0000008A;
}
.name{
    color: black;
    font-weight: 700;
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
}
.results, .new, .clients{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            width: 31%;
            &__bot{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
            }
            &__swiper{
                width: 100%;
                &__slide{
                    width: 100%;
                    padding: 0 min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &__left{
                        width: 25%;
                        aspect-ratio: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #F8F8F8;
                        border-radius: 50%;
                    }
                    &__right{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        width: 70%;
                        &__count{
                            font-weight: 700;
                            font-size: 64px;
                        }
                    }
                }
            }
 }
 .clients{
    width: 31%;
    &__block{
        display: flex;
        flex-wrap: wrap;
        gap: 3.5%;
        width: 100%;
        row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        padding: 0 min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)80px;
        &__in{
            width: 31%;
            aspect-ratio: 1;
            box-shadow: 0px 0px 4px 4px #0000000D inset;
            background-color: #F8F8F8;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }
    }
}     
.blocks{
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__top{
        display: flex;
        flex-wrap: wrap;
        gap: 3.5%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        align-items: stretch;
        .info{
            width: 31%;
            background-color: white;
            padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
            border-radius: 10px;
            display: flex;
            box-shadow: 0px 4px 3px 1px #0000000D;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            &__top{
                display: flex;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                align-items: center;
                &__right{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    &__name{
                        font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                        color: #11415D;
                        font-weight: 700;
                    }
                    &__spec{
                        display: flex;
                        gap: 5px;
                        color: #FD2D2A;
                        font-weight: 600;
                    }
                }
            }
            &__status{
                width: 100%;
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0;
                border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                background-color: #14D448;
                color: white;
                display: flex;
                justify-content: center;
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                span{
                    font-weight: 600;
                }
            }
        }
    }
    &__bot{
        display: flex;
        flex-wrap: wrap;
        gap: 3.5%;
        row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);  
        align-items: stretch;
        &__right{
            width: 65.5%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            &__top, &__bot{
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: stretch;
                .request, .progress1, .statistic, .access{
                    width: 47.3%;
                    display: flex;
                    flex-direction: column;
                    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                }
                .request{
                    &__bot{
                        width: 100%;
                        &__swiper{
                            width: 100%;
                            padding: 0 min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                            &__slide{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 5px;
                                img{
                                    width: 100%;
                                    aspect-ratio: 1;
                                }
                            }
                        }
                    }
                }
                .access{
                    &__bot{
                        padding: 0 min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)10px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4%;
                        row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                        &__in{
                            width: 22%;
                            cursor: pointer;
                        }
                    }
                }
                .progress1{
                    &__block{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 0 min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px)10px;
                        &__in{
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            position: relative;
                            color: #0000008A;
                            &__color{
                                width: 54%;
                                background-color: #FD2D2A;
                                border-radius: 10px;
                                height: 10px;
                            }
                            &__percent{
                                position: absolute;
                                top: 0%;
                                left: 54%;
                                color: black;
                                transform: translate(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>