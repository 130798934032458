<template>
    <div class="mainStyle1">
        <div class="mainStyle1__left">
            <platformTable />
        </div>
        <div class="mainStyle1__right">
            <div class="mainStyle1__right__top">
                <div class="mainStyle1__right__top__left">
                    <p class="mainStyle1__right__top__left__title">Премиум опции</p>
                </div>
                <div class="mainStyle1__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="block">
                <div class="block__in">
                    <p class="block__in__title">Купите новые услуги</p>
                    <div class="block__in__bot">
                        <div class="block__in__bot__in" v-for="item in newOptions" :key="item.id">
                            <grantCard :item="item" :is-to-buy="true"/>
                        </div>
                    </div>
                </div>
                    <div class="block__in" v-if="restock.length > 0">
                        <p class="block__in__title">Restock</p>
                        <div class="block__in__bot">
                            <div class="block__in__bot__in" v-for="item in restock" :key="item.id">\
                                <grantCard :item="item" :is-to-buy="true"/>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import grantCard from '@/components/grantCard.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default {
    components:{
        platformTable, Swiper, SwiperSlide, newCardComponent,userInfo, grantCard
    },
    data(){
        return{
            activeTab: 'list',
            selectedStip: -1,
            selectedStep: -1,
            data: false,
            newOptions: false,
            restock: false
        }
    },
    methods: {
        async getPage() {     
            await axios.get(`/platform/premium-options`)
            .then(res => {
                this.data = res.data.data
                this.newOptions = this.data.filter(item => item.type === 0);
                this.restock = this.data.filter(item => item.type === 1);
            })
            .catch(err => {
            }) 

        },
    },
    
    async created() {
        this.getPage();
    },
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}

.block{
    display: flex;
    flex-direction: column;
    gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    &__in{
        display: flex;
        flex-direction: column;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__title{
            color: #0000008A;
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
        }
        &__bot{
            display: flex;
            flex-wrap: wrap;
            gap: 2.5%;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__in{
                width: 18%;
            }
        }
    }
    @media(max-width:768px){
        gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        &__in{
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
            &__title{
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
            }
            &__bot{
                gap: 2%;
                row-gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                &__in{
                    width: 49%;
                }
            }
        }
    }
}
</style>