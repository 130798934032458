<template>
    <div class="customSelect" :class="{miniSelect: miniSelect}">
        <div class="customSelect__top" @click="openBot = !openBot" :class="{customTop: openBot == true, withoutBorder: isBorder}">
            <p>{{ selectedItem }}</p>
            <img src="@/assets/icons/select.svg"/>
        </div>
        <div class="customSelect__bot" v-if="openBot && list && isAddFile">
            <div class="customSelect__bot__in" 
                 v-for="item in list" 
                 :key="item" 
                 @click="selectItem(item)">
                {{ item.type }}
            </div>
        </div>
        <div class="customSelect__bot" v-if="openBot && list && isAddFile == false">
            <div class="customSelect__bot__in" 
                 v-for="item in list" 
                 :key="item" 
                 @click="selectItem(item)">
                {{ item.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isBorder: Boolean,
        miniSelect: Boolean,
        isAddFile: Boolean,
        isFilter: Boolean,
        list: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: null, // Accepts any type of value
            default: null, // Default value
        }, // Значение для v-model
    },
    data() {
        return {
            openBot: false,
            selectedItem: 'Выбор', // Отображаемый текст выбранного элемента
        };
    },
    mounted() {
        if (this.modelValue || this.modelValue == 0) {
            const foundItem = this.list.find(item => item.id == this.modelValue);
            if (foundItem) {
                this.selectedItem = foundItem.title;
            }
        } else {
            // Обработка случая, когда list не массив или modelValue пустой
            this.selectedItem = 'Выбор'; // или любое другое значение по умолчанию
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(newValue) {
                if(this.isAddFile){
                    const foundItem = this.list.find(item => (item.type) === newValue);
                    this.selectedItem = foundItem ? foundItem.type : 'Выбор';
                }
                if(this.isFilter){
                    const foundItem = this.list.find(item => (item.title) == newValue);
                    this.selectedItem = foundItem ? foundItem.title : 'Выбор';
                }
                else{
                    const foundItem = this.list.find(item => (item.id) === newValue);
                    this.selectedItem = foundItem ? foundItem.title : 'Выбор';}
            },
        },
    },
    methods: {
        selectItem(item) {
        if(this.isAddFile){
            this.selectedItem = item.type;
        } 
        if(this.isFilter){
            this.selectedItem = item.title;
        } 
        else {
            this.selectedItem = item.title;
        }
        this.openBot = false;
        // Эмитируем событие для v-model
        if(this.isAddFile){
            this.$emit('update:modelValue', item.type);
        }
        if(this.isFilter){
            this.$emit('update:modelValue', item.title);
        } 
        else {
            this.$emit('update:modelValue', item.id);
        }
        this.$emit('change', this.isAddFile ? item.type : item.id);
    },
    },
};
</script>

<style lang="scss" scoped>
.customTop {
    border-radius: 10px 10px 0px 0px;
}
.withoutBorder {
    border: none !important;
}
.miniSelect {
    .customSelect__top {
        padding: 5px 10px;
    }
    .customSelect__bot__in {
        padding: 5px 0px;
        p {
            padding: 0 10px;
        }
    }
}
.customSelect {
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    position: relative;
    &__top {
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 100%;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border-radius: 10px;
        border: 1px solid #0000001A;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        text-overflow: ellipsis;
        p {
            margin: 0;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &__bot {
        width: 100%;
        top: 100%;
        border: 1px solid #0000001A;
        border-radius: 10px;
        max-height: 200px;
        overflow: auto;
        position: absolute;
        background-color: #F0F2F5;
        z-index: 999;
        &__in {
            cursor: pointer;
            padding: 10px;
            border-radius: 10px;
            p {
                padding: 0 min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            }
            transition: all 0.5s ease;
            &:hover {
                background-color: #F8F8F8;
                color: black;
            }
        }
    }
}
</style>