<template>
  <div class="main">
    <Burger v-if="openBurger" @closeModal="openBurger = false"/>
    <img src="@/assets/icons/burger.svg" class="burger" @click="openBurger = true"/>
    <router-view/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Burger from "./components/burger.vue";
export default{
  data(){
    return{
      openBurger: false
    }
  },
  components: {
    Burger
  },
  methods:{
    ...mapActions(["requestUser"]),
  },
  async created() {
      this.requestUser();
  },
}
</script>
<style lang="scss">
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
.main{
  position: relative;
}
.burger{
  position: absolute;
  top: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
  left: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
  width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
  @media(min-width: 768px){
    display: none;
  }
}
#app { 
width: 100vw; 
min-height: 100vh; 
display: flex; 
flex-direction: column; 
align-items: stretch; 
} 
 
html,body,p { 
  overflow-x: hidden; 
  margin: 0 ; 
  padding: 0 ; 
  font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
} 
p{
  font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
}
*{
  box-sizing: border-box;
 }
</style>
