<template>
    <div class="notice" :class="{mainStyle3: $store.state.role == 'supervisor'}">
        <div class="notice__left">
            <platformTable />
        </div>
        <div class="notice__right">
            <div class="notice__right__top">
                <div class="notice__right__top__tabs">    
                    <swiper 
                    class="notice__right__top__tabs__swiper"
                    @swiper="getSwiperCategory" 
                    :breakpoints="{
                        0: {
                            slidesPerView: 'auto'
                        }
                    }"
                    :spaceBetween="50"
                    :speed="500"
                    :lazy="true" 
                > 
                    <swiper-slide v-for="(tab, index) in tabs" 
                        :key="tab"
                        :class="{ customTab: selectedTab === index }"
                        @click="selectTab(index)"
                        ref="tabs"> 
                        {{ tab.name }}
                    </swiper-slide> 
                </swiper>
                </div>
                <div class="notice__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="notice__block">
                <div class="notice__block__in" v-for="item in notices" :key="item">
                    <div class="notice__block__in__top">
                        <div class="notice__block__in__top__left">
                            <img src="@/assets/icons/notice.svg"/>
                            <div class="notice__block__in__top__left__info">
                                <div class="notice__block__in__top__left__info__top">
                                    <p class="name">{{ item.name }}</p>
                                    <p class="status"> {{ item.status }}</p>
                                    <p class="statusTime" :style="{color: item.timeStatus}">{{ item.timeStatusText }}</p>
                                </div>
                                <p class="date">{{ item.date }}</p>
                            </div>
                        </div>
                        <button v-if="item.isButton">Открыть документ</button>
                        <button v-if="item.isButtonCalendar">Открыть календарь</button>
                    </div>
                    <div class="notice__block__in__comments" v-if="item.type == 'correct'">
                        <div class="correct" v-for="correct in item.comments" :key="correct">
                            <div class="correct__top">
                                <img src="@/assets/icons/correct.svg"/>
                                <div class="correct__top__info">
                                    <p>{{ correct.name }}</p>
                                    <p class="status">Комментарий пользователя</p>
                                </div>
                            </div>
                            <textarea>To be fair, the whole album is about parodying how Slim Shady used to be with his wild lines, so it actually does hold some substance</textarea>
                            <button>Исправить</button>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>  
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo
    },
    data() {
        return {
            selectedTab: 0,
            openExtend: false,
            swiperRefCategory: null,
            swiperRef: null,
            activeSwiperIndex: 0,
            notices: [
                {
                    name: 'Карина Н.',
                    status: 'добавлена в список клиентов',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'статус договора -',
                    timeStatusText: 'Истек',
                    timeStatus: 'red',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'продлила договор на',
                    timeStatusText: '3 месяца ',
                    timeStatus: 'green',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'дедлайн курса “Elementary” ',
                    timeStatusText: 'просрочен ',
                    timeStatus: 'red',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'дедлайн 1 программы Technology and Policy завершиться ',
                    timeStatusText: 'через 2 недели',
                    timeStatus: 'orange',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'подтвердила/отклонила документ ',
                    timeStatusText: ' “Резюме”',
                    timeStatus: 'Black',
                    isButton: true,
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'запланировал встречу “Обсуждение с куратором”',
                    timeStatusText: '20 ноября 13:00',
                    isButtonCalendar: true,
                    timeStatus: 'black',
                    date: '11.12.2024'
                },
                {
                    name: 'Супервайзер Алия',
                    status: 'отправил(-а) Вам запрос на корректировку информации',
                    date: 'Вчера 14:30',
                    type: 'correct',
                    comments: [
                        {
                            name:'Massachusetts Institute of Technology',
                            comment: 'To be fair, the whole album is about parodying how Slim Shady used to be with his wild lines, so it actually does hold some substance'
                        },{
                            name:'University of London',
                            comment: 'I sometimes wonder how successful investors manage to accumulate enormous wealth from their investment endeavours because I am an avid investor. I currently have equity from a recent house sale that exceeds $545K, but Im not sure what to do with my money next. Is now the right moment to buy stocks, or should I wait for a better opportunity?'
                        },
                    ]
                },
                {
                    name: 'Карина Н.',
                    status: 'Встреча с "имя клиента" “Обсуждение с куратором””',
                    timeStatusText: '20 ноября 13:00',
                    isButtonCalendar: true,
                    timeStatus: 'black',
                    date: '11.12.2024'
                },
                {
                    name: 'Карина Н.',
                    status: 'загрузила оффер в First/second/third choice',
                    date: '11.12.2024'
                },
                
            ],
            tabs: [
                    {
                        name: 'Все'
                    },
                    {
                        name: 'Дедлайны'
                    },
                    {
                        name: 'Встречи'
                    },
                    {
                        name: 'Супервайзер'
                    }
                ],
                breakpoints: {
                0: {
                    slidesPerView: 3.4,
                    spaceBetween: 20,
                },
                },
        };
    },
    methods: {
        selectTab(index) {
            this.selectedTab = index;
            this.swiperRefCategory?.slideTo(index, 500);
            this.scrollToTop();
        },
        getSwiperCategory(swiper) {
            this.swiperRefCategory = swiper;
            this.activeSwiperIndex = swiper.activeIndex;
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
select{
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.correct{
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    width: 30%;
    &__top{
        display: flex;
        gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        &__info{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    textarea{
        width: 100%;
        padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        border-radius: 10px;
        background-color: #11415D;
        color: white;
        font-weight: 600;
        resize: none;
        min-height: 150px;
    }
}
.status{
    color: #0000008A;
}
.notice {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%);
    padding-top: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    min-height: 100vh;
    button{
        background-color: #11415D;
        color: white;
        padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
        border: none;
        height: fit-content;
        border-radius: 10px;
        width: fit-content;
    }
    &__block{
        display: flex;
        flex-direction: column;
        gap: 40px; 
        &__in{
            display: flex;
            flex-direction: column;
            gap: 40px;
            &__top{
                display: flex;
                justify-content: space-between;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                &__left{
                    display: flex;
                    gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    align-items: stretch;
                    img{
                        width: 80px;
                        aspect-ratio: 1;
                    }
                    &__info{
                        display: flex;
                        gap: 10px;
                        justify-content: center;
                        flex-direction: column;
                        &__top{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 5px;
                            align-items: center;
                            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                            .name, .statusTime{
                                font-weight: 600;
                            }
                        }
                        .date{
                            font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                            color: #0000008A;
                        }
                    }
                }
            }
            &__comments{
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
                padding-left: 100px;
            }
        }
    }
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        display: flex;
        flex-direction: column;
        gap: 40px; 
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;&__tabs {
                width: 70%;
                font-weight: 600;
                font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3889))), 36px);;
                color: #00000026;
                display: flex;
                position: relative;
                &__swiper{
                    width: 100%;
                    .swiper-slide {
                        cursor: pointer;
                        width: fit-content;
                    }
                }
                .customTab {
                    color: #000; // Активный таб выделяется
                }
            }
            &__right {
                width: 25%;
            }
        }
    }
}
</style>