<template>
    <div class="addPersonBack">
        <div class="addPerson">
            <div class="addPerson__top">
                <p class="addPerson__top__title" v-if="type == 'new'">Действие</p>
                <p class="addPerson__top__title" v-if="type == 'change'">Перенос клиентов куратора</p>
                <p class="addPerson__top__sub">Введите ID пользователя или найдите его в списке для изменения роли</p>
            </div>  
            <div class="line"></div>
            <div class="customInputWhite" v-if="type == 'new'">
                <label>Выберите действие</label>
                <customSelect />
            </div>
            <div class="customInputWhite">
                <label>ID пользователя</label>
                <input value="897688"/>
            </div>
            <div class="addPerson__info">
                <img src="@/assets/icons/personAdd.svg"/>
                <p class="addPerson__info__name">Кейт М.</p>
                <p class="addPerson__info__gmail">kate.m@mentorme.com</p>
            </div>
            <div class="line"></div>
            <div class="addPerson__buttons">
                <button class="addPerson__buttons__save" v-if="type == 'new'">Назначить</button>
                <button class="addPerson__buttons__save" v-if="type == 'change'">Перенести</button>
                <button class="addPerson__buttons__delete" @click="closeModal()">Закрыть</button>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from './customSelect.vue';
export default {
    components: {
        customSelect
    },
    props: [
      'type'
    ],
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
    .addPersonBack{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        align-items: center;
        background-color: #0000007a;
    }
    .addPerson{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        width: 33%;
        background-color: white;
        padding: 40px 60px;
        border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__info{
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            align-items: center;
            &__name{
                font-weight: 600;
                font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            }
            &__gmail{
                color: #0000008A;
            }
        }
        &__top{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            &__title{
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
                color: #2BC38E;
                font-weight: 600;
            }
            &__sub{
                color: #0000008A;
            }
        }
    &__buttons{
        display: flex;
        width: 100%;
        gap: 4%;
        button{
            width: 48%;
            border: none;
            height: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            background-color: #2BC38E;
            border: 1px solid #2BC38E;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #28906c9f;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2BC38E;
                border: 1px solid #2BC38E;
            }
        }
    }
    }
</style>