<template>
    <div class="mainStyle" :class="{mainStyle3: $store.state.role == 'supervisor'}">
        <div class="mainStyle__left">
            <platformTable />
        </div>
        <div class="mainStyle__right">
            <div class="mainStyle__right__top">
                <div class="mainStyle__right__top__left" v-if="$store.state.role == 'curator'">
                    <p class="mainStyle__right__top__left__title">Программы</p>
                </div>
                <div class="mainStyle__right__top__left" v-if="$store.state.role == 'supervisor'">
                    <p class="mainStyle__right__top__left__text">Платформа ></p>
                    <p class="mainStyle__right__top__left__title">Управление программамиw</p>
                </div>
                <div class="mainStyle__right__top__right">
                    <userInfo />
                </div>
            </div>
            <ProgramsComponent />
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewCardComponent from "@/components/newCardComponent.vue";
import NewProgram from "@/components/newPlatform/newProgram.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
import axios from "axios";
import platformTable from "@/components/platformTable.vue";
import ProgramsComponent from "@/components/programsComponent.vue";
export default {
    components: {
        Swiper,
        SwiperSlide,platformTable,
        ProgramsComponent,
        userInfo,
        NewCardComponent,
        NewProgram,
        customSelect
    },
    data() {
        return {
            openNewProgram: false,
            activeTab: 'list',
            selectedStip: 0,
            selectedStep: 0,
            categories: false,
            programs: false,
            selectedCat: -1,
            steps: false,
            stips: false,
        };
    },
    methods: {
        selectStep(index){
            if(this.selectedStep == index){
                this.selectedStep = -1
            }
            else{
                this.selectedStep = index
            }
        },selectStip(index){
            if(this.selectedStip == index){
                this.selectedStip = -1
            }
            else{
                this.selectedStip = index
            }
        },selectCat(index){
            if(this.selectedCat == index){
                this.selectedCat = -1
            }
            else{
                this.selectedCat = index
            }
        },
        async getPage() {    
            await axios.get(`/platform/scholarships`, {
            })
            .then(res => {
                this.stips = res.data.data
            })
            .catch(err => {
            })   
            await axios.get(`/platform/study-degrees`, {
            })
            .then(res => {
                this.steps = res.data.data
            })
            .catch(err => {
            })       
            await axios.get(`/V1/universityPrograms`, {
            })
            .then(res => {
                this.programs = res.data.universityPrograms
            })
            .catch(err => {
            }) 
            await axios.get(`/platform/categories`, {
            })
            .then(res => {
                this.categories = res.data.data
            })
            .catch(err => {
            }) 
        },
    },
    async created() {
        this.getPage();
    },
};
</script>
<style lang="scss" scoped>
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.inputs{
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
}
.customInput{
    width: 23.5%;
}
.customStip{
    color: white !important;
    background: #FD2D2A !important; 
    font-weight: 600;
    .world__left__block__in__top{
        background-color: white !important;
    }
    img{
        filter: brightness(0) saturate(100%) invert(41%) sepia(98%) saturate(5156%) hue-rotate(345deg) brightness(97%) contrast(105%) !important;
    }
    .category__block__in__top{
        background-color: white !important;
    }
    .line{
        border-top: 1px solid white !important;
    }
}
.world{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &__left{
        width: 60%;
        &__block{
            gap: 2.5%;
            &__in{
                &__top{
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)10px;
                    width: 100%;
                    border-radius: 10px;
                    img{
                        width: 100%;
                    }
                    border: 1px solid #0000001A;
                }
                width: 18%;
            }
        }
    }
    &__right{
        width: 32%;
        &__block{
            gap: 5%;
            &__in1{
                width: 30%;
            }
        }
    }
    &__left, &__right{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block{
            display: flex;
            flex-wrap: wrap;
            row-gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            &__in, &__in1{
                cursor: pointer;
                display: flex;
                padding: 8px 8px 10px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                font-weight: 600;
                img{
                    height: 35px;
                }
            }
            &__in1{
                background-color: #FFFFFF;
                img{
                    height: 35px;
                    filter: brightness(0) saturate(100%) invert(71%) sepia(1%) saturate(503%) hue-rotate(114deg) brightness(98%) contrast(96%);
                }
            }
            &__in{
                color: #FD2D2A;
                background: white;
                img{
                    filter: brightness(0) saturate(100%) invert(64%) sepia(84%) saturate(7188%) hue-rotate(344deg) brightness(102%) contrast(105%);
                }
                .line{
                    border-top: 1px solid #FD2D2A;
                }
            }
        }
    }
}
.category{
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__block{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__in{
            width: 11%;
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            padding: 8px 8px 10px;
            justify-content: flex-start;
            border-radius: 10px;
            cursor: pointer;
            &__top{
                padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)10px;
                width: 100%;
                border-radius: 10px;
                display: flex;
                height: 60px;
                align-items: center;
                justify-content: center;
                img{
                    width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                }
                border: 1px solid #0000001A;
            }
        }
    }
}
select {
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.filter {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    img {
        width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
        height: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    }

    input,
    select,
    &__plus {
        width: 100%;
        padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        background-color: transparent;
        border: 1px solid #0000001A;
        box-shadow: 0px 0px 4px 0px #0000000D;
        border-radius: 10px;
        height: 100%;
    }

    &__search {
        width: 60%;
        position: relative;

        img {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            right: 10px;
            cursor: pointer;
        }
    }

    &__select {
        width: 15%;

        select {
            padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)5px;
        }
    }

    &__plus {
        width: 23%;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
    }
}
.block {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);

    &__in {
        width: 49%;
    }
}
</style>