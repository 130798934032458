<template>
    <div class="mainStyle1">
        <div class="mainStyle1__left">
            <platformTable />
        </div>
        <div class="mainStyle1__right">
            <div class="mainStyle1__right__top">
                <div class="mainStyle1__right__top__left">
                   <p class="mainStyle1__right__top__left__title">Поступление</p>
                </div>
                <div class="mainStyle1__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="swiper1 desctop" >
                <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" :loop="true"
                :coverflowEffect="{
                    rotate: 2,
                    stretch: 0,
                    depth: 100,
                    modifier: 10,
                    scale : 1,
                    slideShadows: true
                }" 
                :breakpoints="{
                    1600: { slidesPerView: 2},
                    768: {slidesPerView: 2}
                }">
                    <swiper-slide v-for="item in admissions" :key="item" class="swiper1__slide" >
                        <div class="cardBack">
                            <div class="card" :style="{ backgroundImage: `url('${item?.program?.images[0].image}')` }">
                                <div class="card__top">
                                    <div class="card__top__left">
                                        <div class="card__top__left__rank">
                                            <img src="@/assets/icons/crown.svg" v-if="item?.admission_status?.priority == 1"/>
                                            <p>{{item?.admission_status?.priority}}</p>
                                        </div>
                                        <div class="card__top__left__text">
                                            <p class="card__top__left__text__title">Bolashak</p>
                                            <p class="card__top__left__text__sub">{{item.program.programName}}</p>
                                        </div>
                                    </div>
                                    <div class="card__top__right">
                                        <p><span>Deadline 1:</span> {{item?.admission_status?.first_date}}</p>
                                        <div class="line3">
                                        </div>
                                        <p><span>Deadline 2:</span> {{item?.admission_status?.second_date}}</p>
                                    </div>
                                </div>
                                <div class="card__bot">
                                    {{item.program.university.title}}
                                </div>
                            </div>
                            <div class="cardBack__bot" @click="$router.push('/testPlatformFind/1')"><p>Подробнее</p></div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper1 mobile" >
                <swiper  :grabCursor="true" :centeredSlides="true" 
                :loop="true"
                :breakpoints="{
                            0: { slidesPerView: 1.2, spaceBetween: 10 },
                        }">
                    <swiper-slide v-for="item in 10" :key="item" class="swiper1__slide">
                        <div class="cardBack">
                            <div class="card">
                                <div class="card__top">
                                    <div class="card__top__left">
                                        <div class="card__top__left__rank">
                                            <img src="@/assets/icons/crown.svg" />
                                            <p>1</p>
                                        </div>
                                        <div class="card__top__left__text">
                                            <p class="card__top__left__text__title">BOLASHAK</p>
                                            <p class="card__top__left__text__sub">Technology and Policy</p>
                                        </div>
                                    </div>
                                    <div class="card__top__right">
                                        <p><span>Deadline 1:</span> 12.12.2024</p>
                                        <div class="line3">
                                        </div>
                                        <p><span>Deadline 2:</span> 12.12.2025</p>
                                    </div>
                                </div>
                                <div class="card__bot">
                                    Massachusetts Institute of Technology
                                </div>
                            </div>
                            <div class="cardBack__bot" @click="$router.push('/testPlatformFind/1')"><p>Подробнее</p></div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="progress1">
                <div class="lineTitle">
                    <div class="line"></div>
                    <p>ПРОГРЕСС ПО ВУЗУ</p>
                    <div class="line"></div>
                </div>
                <div class="progress1__block">
                    <div class="progress1__block__in" v-for="item in progress" :key="item">
                        <div class="progress1__block__in__left">
                            <div class="progress1__block__in__left__img">
                                <img :src="require('@/assets/icons/img1.svg')"/>
                            </div>  
                            <div class="progress1__block__in__left__info">
                                <p class="progress1__block__in__left__info__name" :class="{customText: item.status != 1}">{{ item.documentType }}</p>
                                <p class="progress1__block__in__left__info__text" :class="{customText: item.status != 1}">Подготовка резюме для поступления</p>
                            </div>
                        </div>
                        <div class="progress1__block__in__center">
                            <p class="progress1__block__in__center__status" :class="{customText: item.status != 1}">Дата начала и завершения</p>
                            <p class="progress1__block__in__center__date" :class="{customText: item.status != 1}">{{ item.date_from }} - {{ item.date_to }}</p>
                        </div>
                        <div class="progress1__block__in__right">
                            <button :class="{orangeButton: item.status == 0, greyButton: item.status == 2}">
                                <img src="@/assets/icons/galochkaWhite.svg" v-if="item.status == 1"/>
                                <img src="@/assets/icons/clock.svg" v-if="item.status == 0"/>
                                <img src="@/assets/icons/form.svg" v-if="item.status == 2"/>
                                <p v-if="item.status == 1">Завершено</p>
                                <p v-if="item.status == 0">В процессе</p>
                                <p v-if="item.status == 2">Заполнить форму</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="status">
                <div class="lineTitle">
                    <div class="line"></div>
                    <p>Статус вашей заявки</p>
                    <div class="line"></div>
                </div>
                <div class="status__block">
                    <img src="@/assets/icons/clock.svg"/>
                    <p class="status__block__title">Ждем ответа от ВУЗа</p>
                    <!-- <p class="status__block__text">Мы отправили Вашу заявку на поступление. Ваш куратор свяжется с Вами как только мы получим ответ</p> -->
                </div>
            </div>
            <div class="upload">
                <div class="lineTitle">
                    <div class="line"></div>
                    <p>ЗАГРУЗИТЕ ВАШ ОФФЕР</p>
                    <div class="line"></div>
                </div>
                <div class="upload__block">
                    <label class="upload__block__top" for="document">
                        <img src="@/assets/icons/plusGrey.svg"/>
                        <p>Загрузить файл</p>
                        <input type="file" id="document" style="display: none;"/>
                    </label>
                    <p class="upload__block__conditional">Вид оффера: <span>Conditional</span></p>
                    <!-- <p class="upload__block__text">Мы отправили Вашу заявку на поступление. Ваш куратор свяжется с Вами как только мы получим ответ</p> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo
    },
    data() {
        return {
            progress: [
            ],
            admissions: []
        };
    },
    methods: {
        async getPage(){
            await axios.get(`/platform/client/user-admissions`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
            .then(res => {
                this.admissions = res.data.data
                this.progress = res.data.data[0].docs
            })
            .catch(err => {
            })
        }
    },
    async created() {
        this.getPage()
    }
};
</script>
<style lang="scss" scoped>
.lineTitle{ 
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    color: #0000008A;
    .line{
        width: 35%;
    }
    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
    @media(max-width:768px){
        font-size: 16px;
        .line{
            width: 20%;
        }
    }
}
.orangeButton{
    background: inherit !important;
    color: #FF8800 !important;
    border: 1px solid #FF8800 !important;
}
.greyButton{
    background: inherit !important;
    color: #0000004D !important;
    border: 1px solid #0000004D !important;
}
.customText{
    color: black;
}
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.cardBack{
    background: white;
    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
    display: flex;
    flex-direction: column;
    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    &__bot{
        display: none;  
        p{
            color: black;
            text-align: center;
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
        }
    }
    @media(max-width: 768px){
        padding: 10px;
        gap: 10px;
        &__bot{
            p{
                font-size: 16px;
            }
        }
    }
}
.card{
    width: 100%;
    aspect-ratio: 1.6;
    border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-image: url('@/assets/icons/test.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    color: white;
    &__top{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        &__left{
            width: 50%;
            display: flex;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: center;
            &__rank{
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                img{
                    width: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                }
            }
            &__text{
                display: flex;
                flex-direction: column;
                &__sub{
                    font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    font-weight: 700;
                }
            }
        }
        &__right{
            display: none;
            width: 40%;
            padding: 6px min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
            background-color: #FD2D2A;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            .line3{
                border-top: 1px solid #FFFFFF8A;
                width: 100%;
            }
            height: fit-content;
            span{
                color: #FFFFFF8A;
            }
        }
    }
    &__bot{
        font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.2153))), 32px);
        font-weight: 700;
    }
    @media(max-width:768px){
        padding: 10px;
        &__top{
            flex-direction: column;
            gap: 10px;
            &__left{
                gap: 10px;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                &__text{
                    &__sub{
                        font-size: 16px;
                    }
                }
            }
            &__right{
                width: fit-content;
                padding: 5px;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            }
        }
        &__bot{
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
        }
    }
}
.swiper1{
    width: 100%;
    .swiper-slide-active .cardBack__bot{
        display: block !important;
    }
    .swiper-slide-active .card__top__right  {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    &__slide{
        width: 100%;
        height: 100%;
    }
}
.progress1{
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__block{
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__in{
            display: flex;
            justify-content: space-between;
            width: 100%;
            &__left{
                width: 35%;
                display: flex;
                gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                align-items: center;
                color: #0000004D;
                &__img{
                    border: 1px solid #0000004D;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    border-radius: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.5208))), 12px);
                }
                &__info{
                    &__name{
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                        font-weight: 700;
                    }
                }
                img{
                    width: 40px;
                }
            }
            &__center{
                width: 20%;
                display: flex;
                flex-direction: column;
                color: #0000004D;
                font-size: min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
                &__date{
                    font-weight: 700;
                }
            }
            &__right{
                width: 20%;
                button{
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    padding: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px)0px;
                    background-color: #14D448;
                    color: white;
                    border-radius: 10px;
                    img{
                        width: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                    }
                }
            }
        }
    }
    @media(max-width:768px){
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block{
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            &__in{
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                &__left{
                    width: 100%;
                    gap: 10px;
                    &__img{
                        padding: 10px;
                    }
                    &__info{
                        &__name{
                            font-size: 16px;
                        }
                    }
                }
                &__center{
                    width: 100%;
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                }
                &__right{
                    width: fit-content;
                    button{
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}
.status{
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    align-items: center;
    &__block{
        display: flex;
        flex-direction: column;
        gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        align-items: center;
        &__title{
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
            font-weight: 700;
            color: #FF8800;
        }
        &__text{
        }
    }
    img{
        width: 20%;
    }
    @media(max-width:768px){
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block{
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            &__title{
                font-size: 16px;
            }
        }
        img{
            width: 50%;
        }
    }
}
.upload{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    width: 100%;
    &__block{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        &__conditional{
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            span{
                font-weight: 600;
            }
        }
        &__text{
            color: #0000008A;
        }
        &__top{
            width: 20%;
            padding: 40px 0px;
            display: flex;
            flex-direction: column;
            gap: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
            align-items: center;
            border: 1px solid #0000001A;
            background-color: #F0F2F5;
            border-radius: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            cursor: pointer;
            img{
                width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            }
        }
    }
    @media(max-width:768px){
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        &__block{
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
            &__conditional{
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            }
            &__top{
                padding: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px)0px;
                width: 50%;
            }
        }
    }
}
</style>